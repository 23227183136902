/* eslint-disable jsx-a11y/anchor-is-valid */

import { CrumbHeader } from "../../../general-components/headings/crumb-header";
import { ShadowedCard } from "../../../general-components/panels/shadowed-card";
import { ActivitiesTable } from "../partials/activities-table";

export function Activities() {
  return (
    <>
      <div>
        <ShadowedCard>
          <CrumbHeader fromPosition="Dashboard" url="#" title="Activities" />
        </ShadowedCard>
        <div
          className="card-body"
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <ActivitiesTable className="text-left" />
        </div>

        {/* <Paginator pages={pages} callback={loadBills} /> */}
      </div>
    </>
  );
}
