export const HorizontalApart = ({
  children,
  flexAlignItems,
}: {
  children: JSX.Element[] | JSX.Element;
  flexAlignItems?: string;
}) => {
  return (
    <div className={`d-flex justify-content-between ${flexAlignItems || ""}`}>
      {children}
    </div>
  );
};

export const HorizontalAlign = ({
  children,
  marginTop,
}: {
  children: JSX.Element[] | JSX.Element;
  marginTop?: string;
}) => {
  return (
    <div
      className={`d-flex align-items-center ${marginTop ? marginTop : "mt-2"}`}
    >
      {children}
    </div>
  );
};
