import { Navigate, Route, Routes, Outlet } from "react-router-dom";

import { PageLink, PageTitle } from "../../../_metronic/layout/core";

import { SidebarMenuItem } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem";
import { SidebarMenuItemWithSub } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItemWithSub";
import { Suspense, lazy } from "react";
import { SimpleLoader } from "../../general-components/loaders/simple-loader";

import { CostOfService } from "../Finance/components/cos/cost-of-service";
import ViewCos from "../Finance/components/cos/view-cos";

const innerRouteBreadCrumbs: Array<PageLink> = [
  {
    title: "Invoicing",
    path: "/invoicing/revenue-dashboard",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const FinancePage = () => {
const FinanceDashboard = lazy(() => import("./components/dashboard")) ;

  const RevenueDashboard = lazy(() => import("./components/revenue-dashboard"));
  const CollectionsDashboard = lazy(
    () => import("./components/collections-dashboard")
  );
  const Invoices = lazy(() => import("./components/invoice/invoices"));
  const Receipts = lazy(() => import("./components/receipts/receipts"));
  const CreditNotes = lazy(
    () => import("./components/credit-notes/credit-notes")
  );
  const VATReports = lazy(() => import("./components/vat-reports"));
  const CollectionsReports = lazy(
    () => import("./components/collections-reports")
  );
  const ReceivablesReports = lazy(
    () => import("./components/receivables-reports")
  );
  const CreditNotesReports = lazy(
    () => import("./components/credit-notes-reports")
  );

  const ExpensesDashboard = lazy(
    () => import("./components/expenses-dashboard")
  );
  
  const ExpensesCategories = lazy(
    () => import("./components/expenses-categories")
  );
  const ScheduledExpenses = lazy(
    () => import("./components/scheduled-expenses")
  );
  const ExpensesPayments = lazy(() => import("./components/expenses-payments"));
  const ExpensesBills = lazy(
    () => import("../Finance/components/expense-bill/expenses-bills")
  );
  const BillPayments = lazy(
    () => import("./components/bill-payments/bill-payments")
  );
  const ExpensesReport = lazy(() => import("./components/expenses-report"));
  const Journals = lazy(() => import("./components/journals/journals"));
  const JournalView = lazy(() => import("./components/journals/journal-view"));

  const AllAccounts = lazy(() => import("../Finance/components/cashbook-all-accounts"));
  const InternalTransfers = lazy(
    () => import("../Finance/components/cashbook-internal-transfers")
  );
  const AccountsNew = lazy(() => import("../Finance/components/account-new"));
  const InternalTransferNew = lazy(
    () => import("../Finance/components/internal-transfer-new")
  );
  const Transactions = lazy(() => import("../Finance/components/transactions"));


  const ViewInvoice = lazy(() => import("./components/invoice/view-invoice"));
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="revenue-dashboard"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Revenue Dashboard
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <RevenueDashboard />
              </Suspense>
            </>
          }
        />

        <Route
          path="collections-dashboard"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Collections Dashboard
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <CollectionsDashboard />
              </Suspense>
            </>
          }
        />

        <Route
          path="cost-of-service"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Cost of Service
              </PageTitle>
              <CostOfService />
            </>
          }
        />

        <Route
          path="cost-of-service/:id"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                View Cost of Service
              </PageTitle>
              <ViewCos />
            </>
          }
        />

        <Route
          path="cashbooks"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Accounts
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <AllAccounts />
              </Suspense>
            </>
          }
        />

        <Route
          path="cashbooks/new"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                New Account
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <AccountsNew />
              </Suspense>
            </>
          }
        />

        <Route
          path="cashbook-transfers"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Transfers
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <InternalTransfers />
              </Suspense>
            </>
          }
        />
        <Route
          path="transactions/:id"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Transactions
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <Transactions />
              </Suspense>
            </>
          }
        />
        <Route
          path="cashbook-transfers/new"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Internal Transfers New
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <InternalTransferNew />
              </Suspense>
            </>
          }
        />

        <Route
          path="invoices"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Invoices
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <Invoices />
              </Suspense>
            </>
          }
        />
        <Route
          path="invoices/:id"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                View Invoice
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ViewInvoice />
              </Suspense>
            </>
          }
        />

        <Route
          path="receipts"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Receipts
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <Receipts />
              </Suspense>
            </>
          }
        />

        <Route
          path="credit-notes"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Credit Notes
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <CreditNotes />
              </Suspense>
            </>
          }
        />

        <Route
          path="vat-reports"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                VAT Reports
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <VATReports />
              </Suspense>
            </>
          }
        />

        <Route
          path="collections-reports"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Collections Reports
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <CollectionsReports />
              </Suspense>
            </>
          }
        />

        <Route
          path="receivables-reports"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Receivables Reports
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ReceivablesReports />
              </Suspense>
            </>
          }
        />

        <Route
          path="credit-notes-reports"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Credit Notes Reports
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <CreditNotesReports />
              </Suspense>
            </>
          }
        />
{/* //TODO: put this route where it belongs */}
       <Route
          path="dashboard"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Credit Notes Reports
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <FinanceDashboard />
              </Suspense>
            </>
          }
        />

        <Route
          path="expenses-dashboard"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Expenses Dashboard
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ExpensesDashboard />
              </Suspense>
            </>
          }
        />

        <Route
          path="expenses-categories"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Expense Categories
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ExpensesCategories />
              </Suspense>
            </>
          }
        />

        <Route
          path="scheduled-expenses"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Scheduled Expenses
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ScheduledExpenses />
              </Suspense>
            </>
          }
        />

        <Route
          path="expenses-payments"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Expenses Payments
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ExpensesPayments />
              </Suspense>
            </>
          }
        />

        <Route
          path="expenses"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Expenses Bills
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ExpensesBills />
              </Suspense>
            </>
          }
        />

        <Route
          path="payments"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Bill Payments
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <BillPayments />
              </Suspense>
            </>
          }
        />

        <Route
          path="journals"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Journals
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <Journals />
              </Suspense>
            </>
          }
        />

        <Route
          path="journals/:id"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Journals
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <JournalView />
              </Suspense>
            </>
          }
        />

        <Route
          path="expenses-report"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Expenses Report
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ExpensesReport />
              </Suspense>
            </>
          }
        />

        <Route index element={<Navigate to="/finance/invoices" />} />
      </Route>
    </Routes>
  );
};

export default FinancePage;

export const InvoicingRoutes = () => (
  <SidebarMenuItemWithSub
    to="/finance"
    title="Finance"
    // fontIcon="bi-tag"
    icon="tag"
  >
    {/* <SidebarMenuItem
      to="/invoicing/revenue-dashboard"
      title="Revenue Dashboard"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    {/* <SidebarMenuItem
      to="/invoicing/collections-dashboard"
      title="Collections Dashboard"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    <SidebarMenuItem
      to="/finance/invoices"
      title="Invoices"
      icon="cheque"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/finance/receipts"
      title="Receipts"
      icon="bill"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/finance/credit-notes"
      title="Credit Notes"
      icon="notepad-edit"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/finance/expenses"
      title="Expenses"
      icon="delete-files"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/finance/payments"
      title="Payments"
      icon="file-added"
      fontIcon="bi-person"
    />
    <SidebarMenuItem
      to="/finance/journals"
      title="Journals"
      icon="calculator"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/finance/cost-of-service"
      title="Cost of Service"
      icon="data"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/finance/cashbooks"
      title="Accounts"
      icon="bill"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/finance/cashbook-transfers"
      title="Transfers"
      icon="update-file"
      fontIcon="bi-person"
    />

    {/* <SidebarMenuItem
      to="/invoicing/vat-reports"
      title="VAT Reports"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    {/* <SidebarMenuItem
      to="/invoicing/collections-reports"
      title="Collections Reports"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    {/* <SidebarMenuItem
      to="/invoicing/receivables-reports"
      title="Receivables Reports"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    {/* <SidebarMenuItem
      to="/invoicing/credit-notes-reports"
      title="Credit Notes Reports"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}
  </SidebarMenuItemWithSub>
);
