import { FC, ReactElement, useEffect } from "react";

type Props = {
  iconState: Boolean | boolean;
  setIconState: (iconState: Boolean | boolean) => void;
  children: ReactElement[] | ReactElement;
  panelName: string;
  isExpanded?: boolean;
};

type PropsNormalPanel = {
  children?: ReactElement[] | ReactElement;
};
export const CollapsiblePanel: FC<Props> = ({
  setIconState,
  iconState,
  children,
  panelName,
}) => {
  return (
    <div className="collapse" id={panelName}>
      <div className="card mt-2 position-relative shadow">
        <div className="card-body py-2">
          <button
            type="button"
            className="position-absolute top-0 end-0 m-2 btn btn-icon btn-icon-danger"
            data-bs-toggle="collapse"
            data-bs-target={`#${panelName}`}
            aria-expanded="false"
            aria-controls={panelName}
            onClick={() => {
              setIconState(!iconState);
            }}
          >
            <i className={`fas fa-times fs-1`} style={{ color: "red" }}></i>
          </button>

          <div className="row m-7 d-flex flex-wrap">{children}</div>
        </div>
      </div>
    </div>
  );
};

export const Panel: FC<PropsNormalPanel> = ({ children }) => {
  return (
    <div>
      <div className="card mt-2  shadow">
        <div className="card-body py-2">
          <div className="row m-7 d-flex flex-wrap">{children}</div>
        </div>
      </div>
    </div>
  );
};

export const PanelOpenButton: FC<Omit<Props, "children">> = ({
  iconState,
  setIconState,
  panelName,
  isExpanded, //This needs to be TRUE so the panel to be expanded on page loads
}) => {
  useEffect(() => {
    if (!iconState && isExpanded) {
      const toBeClicked = document.getElementById("button-collapsing");
      toBeClicked?.click();
    }
  }, []);
  if (iconState) return null;
  return (
    <button
      className="btn btn-icon btn-secondary btn-sm me-1 align-self-center"
      data-bs-toggle="collapse"
      data-bs-target={`#${panelName}`}
      aria-expanded="false"
      aria-controls={panelName}
      id="button-collapsing"
      onClick={() => {
        setIconState(!iconState);
      }}
    >
      <i className={`fas fa-${iconState ? "times" : "search"} fs-4`}></i>
    </button>
  );
};

export const RowWrapper: FC<PropsNormalPanel> = ({ children }) => {
  return <div className="row m-1 d-flex flex-wrap">{children}</div>;
};
