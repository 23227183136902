import * as Yup from "yup";
export const CosInitialValues = {
  cos_date_start: "",
  cos_date_end: "",
  provider_id: "",
  provider_date: "",
  currency_id: "",
  cos_exch_rate: "",
  cos_provider_reference: "",
  csv_file: null,
};

export const cosschema = Yup.object().shape({
  cos_date_start: Yup.string().required("Please provide Start Date"),
  cos_date_end: Yup.string().required("Please provide End Date"),
  provider_id: Yup.string().required("Please provide Provider"),
  provider_date: Yup.string().required("Please provide Provider Date"),
  currency_id: Yup.string().required("Please provide Currency"),
  cos_exch_rate: Yup.number().required("Please provide Exch. Rate"),
  cos_provider_reference: Yup.string().required(
    "Please provide Provider Reference"
  ),
  csv_file: Yup.mixed()
    .required("A file is required")
    .test(
      "fileSize",
      "File size is too large",
      (value: any) => value && value.size <= 2000000 // 2MB
    )
    .test(
      "fileType",
      "Unsupported file format",
      (value: any) => value && value.type === "text/csv"
    ),
});
export type CosType = {
  cos_id?: number | string | undefined;
  cos_date_start?: string | null | undefined;
  cos_date_end?: string | null | undefined;
  provider_id?: string | null | undefined;
  provider_date?: string | null | undefined;
  currency_id?: string | null | undefined;
  cos_exch_rate?: number | null | undefined;
  cos_provider_reference?: string | null | undefined;

  cos_total_ok?: string | null;
  cos_total_unknown?: string | number | null;
  cos_total_suspended?: string | number | null;
  cos_total?: string | number | null;
  provider_name?: string | number | null;
  user?: string | number | null;
  currency_name?: string | number | null;
  base_currency?: string | number | null;
  cos_total_waste?: string | number | null;
  cos_waste?: string | number | null;
};

export const COSVIEWURLMapper = {
  sort_order: "a",
  sort_by: "b",
  cos_id: "c",
  date_from: "d",
  date_to: "e",
  provider_id: "f",
  asset_id: "g",
  cosi_identification: "h",
  cosi_flag_status: "i",
  subscription_status: "w",
};
