import { FC } from "react";
import SearchableDropdown from "../../../../../general-components/forms/select-element";
import { AssetsApi } from "../../../../../apis/AssetsApi";

const API = new AssetsApi();
export const FilterAsset: FC<{
  asset_id?: string | number;
  data_holder: string;
  size?: string;
  label?: string;
  handleChange: (holder: string, value: any) => void;
}> = ({ asset_id, data_holder, handleChange, size, label }) => {
  return (
    <SearchableDropdown
      label={label || "Asset:"}
      apiCall={async (params?: string) => {
        let results = await API.pullAllAssets(params);
        return API.extractAssets(results);
      }}
      search_key="asset_name"
      label_name="asset_name"
      value_name="asset_id"
      size={`${size ? size : "col-sm-3"}`}
      marginTop="0"
      fetcher={async (setWaitingState) => {
        //set state must be there
        if (!asset_id) return;
        setWaitingState(true);
        let results: any = await API.getOneAssets(asset_id as string);
        setWaitingState(false);
        if (results && results.asset_id && results.asset_name) {
          return {
            value: results.asset_id,
            label: results.organization_name,
          };
        }
        return null;
      }}
      onChange={(value: any) => {
        //   handleQueryChange("organization_id", value?.value);
        handleChange(data_holder, value?.value);
      }}
    />
  );
};
