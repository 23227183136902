import { Navigate, Route, Routes, Outlet } from "react-router-dom";

import { PageLink, PageTitle } from "../../../_metronic/layout/core";

import { ActionList } from "./components/action-list";

import { MyTasks } from "./components/my-tasks";
import { SidebarMenuItem } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem";
import { SidebarMenuItemWithSub } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItemWithSub";

const innerRouteBreadCrumbs: Array<PageLink> = [
  {
    title: "ActionTracker ",

    path: "/action-tracker/action-list",

    isSeparator: false,

    isActive: false,
  },

  {
    title: "",

    path: "",

    isSeparator: true,

    isActive: false,
  },
];

const ActionTrackerPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="action-list"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Action List
              </PageTitle>
              <ActionList />
            </>
          }
        />

        <Route
          path="my-tasks"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                My Tasks
              </PageTitle>
              <MyTasks />
            </>
          }
        />

        <Route index element={<Navigate to="/action-tracker/action-list" />} />
      </Route>
    </Routes>
  );
};

export default ActionTrackerPage;

export const ActionTrackerRoutes = () => <></>;

/**
 *  <SidebarMenuItemWithSub
    to="/action-tracker"
    title="ActionTracker "
    fontIcon="bi-sticky"
    icon="kanban"
  >
    <SidebarMenuItem
      to="/action-tracker/action-list"
      title="Action List"
      icon="credit-cart"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/action-tracker/my-tasks"
      title="My Tasks"
      icon="credit-cart"
      fontIcon="bi-person"
    />
  </SidebarMenuItemWithSub>
 */
