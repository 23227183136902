import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../modules/Auth/core/_requests";
import { downloadFile } from "./file-download";

export class CosApi {
  private url: string = "finance/cost-of-service";
  createCostOfService = (data: any) => {
    return httpPost(`${this.url}/import`, data, true);
  };

  saveCostOfService = (data: any) => {
    return httpPost(`${this.url}`, data);
  };

  //update CostOfService
  updateCostOfService = (id: number | string, data: any) => {
    return httpPut(`${this.url}/${id}`, data);
  };

  //Get all CostOfService
  pullAllCostOfService = (params?: string) => {
    return httpGet(`${this.url}${params ? "?" + params : ""}`);
  };

  getCostOfServiceItems = (id: number | string, params?: string) => {
    return httpGet(`${this.url}/${id}/items${params ? "?" + params : ""}`);
  };

  exportCostOfServiceItems = (id: number | string, params?: string) => {
    return downloadFile(
      `${this.url}/${id}/export${params ? "?" + params : ""}`,
      "cost-of-service.csv"
    );
  };

  //Get one CostOfService
  getOneCostOfService = (id: number | string) => {
    return httpGet(`${this.url}/${id}`);
  };

  //Get all CostOfService
  deleteCostOfService = (id: number | string) => {
    return httpDelete(`${this.url}/${id}`);
  };

  extractCos = (results: any) => {
    if (results.data && results.data.status) {
      return results.data.data;
    } else {
      return false;
    }
  };

  errorMessage = (results: any) => {
    if (results.data && results.data.status === false) {
      return results.data.message;
    } else {
      return "An error have occured";
    }
  };
}
