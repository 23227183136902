import { FC, ReactNode } from "react";
import { SpinnerSm } from "../elements/spinners";

type Props = {
  title?: string;
  type?: string;
  action?: () => void;
  actionFn?: () => void;
  children?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  size?: string;
};

type SubmitProp = Props & { disabled?: boolean };

export const SearchButton: FC<Props> = ({
  title,
  type,
  action,
  loading,
  disabled,
}) => {
  return (
    <div className="col-sm-2 mt-2">
      <button
        className="btn btn-sm btn-success mt-6"
        onClick={(e) => {
          e.preventDefault();
          if (action) action();
        }}
        disabled={loading || disabled}
      >
        <SpinnerSm label={title} loading={loading} />
      </button>
    </div>
  );
};

export const SaveButton: FC<Props> = ({
  title,
  type,
  action,
  loading,
  disabled,
  size,
}) => {
  return (
    <div className={`mt-2 ${size ? size : "col-sm-2"}`}>
      <button
        className="btn btn-sm btn-success mt-6"
        disabled={loading || disabled}
      >
        <SpinnerSm label={title} loading={loading} />
      </button>
    </div>
  );
};

export const SubmitButton: FC<SubmitProp> = ({
  title,
  type,
  actionFn,
  disabled,
}) => {
  return (
    <div className="col-sm-2 mt-2">
      <button
        className="btn btn-sm btn-success mt-6"
        type="submit"
        disabled={disabled}
      >
        {title || "Submit"}
      </button>
    </div>
  );
};
