import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { Overview } from "./components/Overview";
import { Activities } from "./components/Activities";
import { DashboardPage as Dashboard } from "./components/DashboardWrapper";
import { SidebarMenuItem } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem";
import { SidebarMenuItemWithSub } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItemWithSub";

const chatBreadCrumbs: Array<PageLink> = [
  {
    title: "Dashboard",
    path: "/dashboard/dashboard-overall",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const DashboardPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="dashboard-overall"
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Dashboard</PageTitle>
              <Dashboard />
            </>
          }
        />
        <Route
          path="dashboard-activities"
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Activities</PageTitle>
              <Activities />
            </>
          }
        />

       {/* <Route
          path="dashboard"
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Activities</PageTitle>
             
              <h1 className="display-1">This is not working</h1>
            </>
          }
        /> */}
        <Route
          path="dashboard-overview"
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Overview</PageTitle>
              <Overview />
            </>
          }
        />
        <Route
          index
          element={<Navigate to="/dashboard/dashboard-overview" />}
        />
      </Route>
    </Routes>
  );
};

export default DashboardPage;

export const DashboardRoutes = () => (
  <SidebarMenuItemWithSub
    to="/dashboard"
    icon="element-11"
    title="Dashboards"
    fontIcon="bi-app-indicator"
  >
    <SidebarMenuItem
      to="/dashboard/dashboard-overview"
      title="Overview"
      icon="chart"
      fontIcon="bi-person"
    />
    <SidebarMenuItem
      to="/billing/dashboard"
      title="Billing"
      icon="dollar"
      fontIcon="bi-person"
    />
    <SidebarMenuItem
      to="/finance/dashboard"
      title="Finance"
      icon="tag"
      fontIcon="bi-person"
    />
    <SidebarMenuItem
      to="/inventory/dashboard"
      title="Inventory"
      icon="barcode"
      fontIcon="bi-person"
    />
    <SidebarMenuItem
      to="/sales-management/dashboard"
      title="Sales"
      icon="briefcase"
      fontIcon="bi-person"
    />
    {/* <SidebarMenuItem
      to="/dashboard/dashboard-activities"
      title="Activities"
      // icon="burger-menu-1"
      fontIcon="bi-person"
    /> */}
  </SidebarMenuItemWithSub>
);
