import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
export const setting_action_keys = {
  template: "template",
  invoice_signing: "invoice_signing",
  accounting_controls: "accounting_controls",
  quickbook_integration: "quickbook_integration",
  notifications: "notifications",
  totalvfd_integration: "totalvfd_integration",
  emails_templates: "emails_templates",
};
export interface SettingsActions {
  type: string; // Common action type property
  payload?: any; // Payload for actions carrying data
}
export interface SettingsType {
  isLoading: boolean;
  data: { [x: string]: any };
  updated: { [x: string]: { [y: string]: any } };
  error: null | string;
}
const initialState: SettingsType = {
  isLoading: false,
  data: {},
  updated: {},
  error: null,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    startSettingFetching(state) {
      state.isLoading = true;
      state.error = null;
    },
    doneSettingFetching(state, action: { payload: any }) {
      state.isLoading = false;
      state.data = arrayToObject(action.payload);
    },

    updateFile(
      state,
      action: PayloadAction<{
        //so we have multiple copies of data to be edited

        template: string;
        setting_name: string;
        setting_group: string;
        setting_value: any;
      }>
    ) {
      if (!state.updated[action.payload.template]) {
        state.updated[action.payload.template] = {
          ...state.data,
          [action.payload.setting_name]: action.payload.setting_value,
        };
      } else {
        state.updated[action.payload.template] = {
          ...state.updated[action.payload.template],
          [action.payload.setting_name]: action.payload.setting_value,
        };
      }

      // console.log(state.updated[action.payload.type]);
    },
    updateSettings(
      state,
      action: PayloadAction<{
        //so we have multiple copies of data to be edited
        template: string;
        setting_name: string;
        setting_group: string;
        setting_value: any;
        complex?: boolean; //if its complex then its an object we should add it as object
      }>
    ) {
      if (!action.payload.complex) {
        if (!state.updated[action.payload.template]) {
          state.updated[action.payload.template] = {
            ...state.data,
            [action.payload.setting_group]: {
              ...state.data[action.payload.setting_group],
              [action.payload.setting_name]: action.payload.setting_value,
            },
          };
        } else {
          state.updated[action.payload.template] = {
            ...state.updated[action.payload.template],
            [action.payload.setting_group]: {
              ...state.updated[action.payload.template][
                action.payload.setting_group
              ],
              [action.payload.setting_name]: action.payload.setting_value,
            },
          };
        }
      } else {
        //This is when we send in objects as value ex: "t1" : {"title":"", "text":""}
        if (!state.updated[action.payload.template]) {
          state.updated[action.payload.template] = {
            ...state.data,
            [action.payload.setting_group]: {
              ...state.data[action.payload.setting_group],
              [action.payload.setting_name]: {
                ...state.data[action.payload.setting_group][
                  action.payload.setting_name
                ],
                ...state.updated[action.payload.template]?.[
                  action.payload.setting_group
                ]?.[action.payload.setting_name], // Preserve previous updates
                ...action.payload.setting_value, // Apply the new value
              },
            },
          };
        } else {
          state.updated[action.payload.template] = {
            ...state.updated[action.payload.template],
            [action.payload.setting_group]: {
              ...state.updated[action.payload.template][
                action.payload.setting_group
              ],
              [action.payload.setting_name]: {
                ...state.updated[action.payload.template][
                  action.payload.setting_group
                ][action.payload.setting_name],
                ...action.payload.setting_value, // Apply the new value
              },
            },
          };
        }
      }
      // console.log(state.updated[action.payload.type]);
    },
    startSettingUpdate(state, action: PayloadAction<{ key: string }>) {
      if (state.updated[action.payload.key]) {
        state.updated[action.payload.key]["wait"] = true;
      }
    },
    failedSettingUpdate(state, action: PayloadAction<{ key: string }>) {
      if (state.updated[action.payload.key]) {
        state.updated[action.payload.key]["wait"] = false;
        state.updated[action.payload.key]["error"] = "Updating failed.";
      }
    },
    cancelSettingUpdate(state, action: PayloadAction<{ key: string }>) {
      if (state.updated[action.payload.key]) {
        delete state.updated[action.payload.key];
      }
    },
    successSettingUpdate(
      state,
      action: PayloadAction<{ key: string; msg?: string }>
    ) {
      if (state.updated[action.payload.key]) {
        if (action.payload.msg) {
          state.updated[action.payload.key]["msg"] = action.payload.msg;
        }
      }
    },
    failedSettingFetching(state, action: { payload: string }) {
      state.isLoading = false;
      state.data = {};
      state.error = action.payload;
    },
  },
});

export const {
  startSettingFetching,
  doneSettingFetching,
  failedSettingFetching,
  updateSettings,
  cancelSettingUpdate,
  startSettingUpdate,
  failedSettingUpdate,
  successSettingUpdate,
  updateFile,
}: any = settingsSlice.actions;
export default settingsSlice.reducer;

const arrayToObject = (arr: any) => {
  let obj: any = {};
  if (!Array.isArray(arr)) return obj;
  arr.forEach((obje) => {
    if (!obj[obje.setting_group]) {
      obj[obje.setting_group] = {
        [obje.setting_name]: obje.setting_value,
      };
    } else {
      obj[obje.setting_group][obje.setting_name] = obje.setting_value;
    }
  });

  return obj;
};
