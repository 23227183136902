let subdomain = "";
const hostname = window.location.hostname; //current page URL
const parts = hostname.split(".");

if (parts.length > 2) {
  // subdomain = parts.slice(0, parts.length - 2).join(".");
  // just pick the first part
  subdomain = parts[0];
}

let BASE_URL = "";
let BASE_URL_APP = "";

if (process.env.REACT_APP_ENV === "development") {
  BASE_URL = `http://${subdomain}.api.trackmis.com.local:8280/`;
  // BASE_URL = `http://${subdomain}.api.trackmis.com:8280/`;
  BASE_URL = "https://demo.api.trackmis.com/";
  BASE_URL_APP = "http://demo.trackmis.com.local:3000/";
} else {
  BASE_URL = `https://${subdomain}.api.trackmis.com/`;
  BASE_URL_APP = `https://${subdomain}.trackmis.com/`;
}

export { BASE_URL, BASE_URL_APP };

export const WialonDNS = "https://hosting.wialon.com/";
export const LAST_VISITED = "LAST_VISITED";
export const LOGIN_PAGE = "/auth/login";
