import { useEffect, useState } from "react";
import { CurrencyApi } from "../../../../apis/currencyApi";
import { CurrencyType } from "./currency-settings";

const api = new CurrencyApi();

export const useGetCurrency = (): {
  currencyIsLoading: boolean;
  currencies: CurrencyType[];
} => {
  const [currencyIsLoading, setCurrencyIsLoading] = useState<boolean>(false);
  const [currencies, setCurrencies] = useState<CurrencyType[]>([]);

  const fetchCurrency = async () => {
    try {
      setCurrencyIsLoading(true);
      let results: any = await api.pullAllCurrency();
      if (results) {
        setCurrencies(results);
      }
    } catch (ex: any) {
      console.log(ex);
    } finally {
      setCurrencyIsLoading(false);
    }
  };
  useEffect(() => {
    fetchCurrency();
  }, []);
  return { currencyIsLoading, currencies };
};
