import { FC, useState } from "react";
import { CosApi } from "../../../../apis/cosApi";
import { useFormik } from "formik";
import { CosInitialValues, cosschema, CosType } from "./cos.type";
import { SaveButton } from "../../../../general-components/forms/search-button";
import {
  ValidatedInput,
  ValidatedSelectInput,
} from "../../../../general-components/forms/input-element";
import SearchableDropdown from "../../../../general-components/forms/select-element";
import { RowWrapper } from "../../../../general-components/panels/collapsible-panel";
import { useAuth } from "../../../Auth";
import { useGetProviders } from "../../../../general-components/hooks/providers/use-providers";
import { LoadingFormComponent } from "../../../../general-components/loaders/simple-loader";
import { MISTable } from "../../../../general-components/tables/mis-table";
import {
  DangerBadge,
  SuccessBadge,
} from "../../../../general-components/elements/badges";
import { ProvidersType } from "../../../Settings/components/providersType";
import { MIS_DANGER } from "../../../../../_metronic/mis.themes";
import { useGetCurrency } from "../../../Settings/components/currency/use-get-currency";
const API = new CosApi();

type TempTypeForForm = {
  cos_date_start: string;
  cos_date_end: string;
  provider_id: string;
  provider_date: string;
  currency_id: string;
  cos_exch_rate: string;
  cos_provider_reference: string;
  csv_file: File | null;
};
export const CreateCos: FC<{ callback: (data: CosType) => void }> = ({
  callback,
}) => {
  const [saving, setIsaving] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [msg, setMessage] = useState<boolean>(false);
  const [newCos, setNewCos] = useState<
    | {
        cos: any;
        cos_items: any[];
      }
    | undefined
  >(undefined);
  const [proviAndCurrency, setProviAndCurrency] = useState<{
    currency: string;
    provider: string;
  }>({ currency: "", provider: "" });
  const { isLoadingProviders, providersList, mapForListItems } =
    useGetProviders();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [successConfirming, setSuccessConfirming] = useState<boolean>(false);

  const { currencies } = useGetCurrency();

  const saveCos = async () => {
    if (!newCos) return;
    setSuccessConfirming(false);
    try {
      setIsSaving(true);
      let results = await API.saveCostOfService(newCos);

      setSuccessConfirming(true);
    } catch (ex: any) {
      console.log(ex);
    } finally {
      setIsSaving(false);
    }
  };
  const cancelCos = () => {
    setNewCos(undefined);
    setProviAndCurrency({ currency: "", provider: "" });
  };
  const formik = useFormik({
    validationSchema: cosschema,
    initialValues: CosInitialValues,
    onSubmit: async (values, { resetForm }) => {
      const { csv_file, ...rest } = values;
      let formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key as keyof TempTypeForForm] as any);
      });
      try {
        setMessage(false);
        setIsaving(true);
        setError(false);
        let results = await API.createCostOfService(formData);
        results = API.extractCos(results);
        if (results) {
          setNewCos({
            cos: rest,
            cos_items: results as any,
          });
          // callback(results as CosType); //sending it back
        }
        // console.log(results);

        setMessage(true);
        resetForm();
      } catch (ex) {
        // console.log(ex);
        setError(true);
        setMessage(false);
      } finally {
        setIsaving(false);
      }
    },
  });
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    formik.setFieldValue("csv_file", file);
  };
  return (
    <>
      {successConfirming ? (
        <h2 className="text-success">
          Successfully imported Cost of Service data
        </h2>
      ) : (
        <>
          {newCos ? (
            <div>
              <MISTable>
                <thead>
                  <tr>
                    <td>
                      <strong>#</strong>
                    </td>
                    <td>
                      <strong>Start Date</strong>
                    </td>
                    <td>
                      <strong>End Date</strong>
                    </td>
                    <td>
                      <strong>Provider</strong>
                    </td>
                    <td>
                      <strong>Identification</strong>
                    </td>
                    <td>
                      <strong>Amount({proviAndCurrency.currency})</strong>
                    </td>
                    <td>
                      <strong>Status</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {newCos &&
                    newCos.cos_items.map((ii: any, idx: number) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{ii.cosi_date_start}</td>
                        <td>{ii.cosi_date_end}</td>
                        <td>{proviAndCurrency.provider}</td>
                        <td>{ii.cosi_identification}</td>
                        <td>{ii.cosi_cost_amount}</td>
                        <td>
                          {ii.cosi_flag_status === "UNKNOWN" ? (
                            <DangerBadge>{ii.cosi_flag_status}</DangerBadge>
                          ) : (
                            <SuccessBadge>{ii.cosi_flag_status}</SuccessBadge>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </MISTable>
              <button
                className="btn btn-sm btn-success"
                disabled={isSaving}
                onClick={saveCos}
              >
                {isSaving ? "Saving..." : "Save"}
              </button>
              {isSaving ? (
                <button
                  className="btn btn-sm"
                  style={{ backgroundColor: MIS_DANGER, color: "white" }}
                  onClick={cancelCos}
                >
                  Cancel
                </button>
              ) : null}
            </div>
          ) : (
            <form onSubmit={formik.handleSubmit} noValidate>
              <RowWrapper>
                <ValidatedInput
                  label="Start Date"
                  size="col-sm-3"
                  type="date"
                  field={formik.getFieldProps("cos_date_start")}
                  form={formik}
                />

                <ValidatedInput
                  label="End Date"
                  size="col-sm-3"
                  field={formik.getFieldProps("cos_date_end")}
                  type="date"
                  form={formik}
                />

                {isLoadingProviders ? (
                  <LoadingFormComponent size="col-sm-4" />
                ) : (
                  <ValidatedSelectInput
                    label="Provider"
                    value={formik.values.provider_id}
                    size="col-sm-4"
                    onChange={(value) => {
                      console.log(value);
                      let ProvObj = providersList.find(
                        (ii: ProvidersType) =>
                          ii.provider_id === parseInt(value)
                      );

                      if (ProvObj) {
                        setProviAndCurrency({
                          ...proviAndCurrency,
                          provider: ProvObj.provider_name as string,
                        });
                      }
                      formik.setFieldValue("provider_id", value);
                    }}
                    onBlur={() => formik.setFieldTouched("provider_id", true)}
                    error={
                      formik.touched.provider_id && formik.errors.provider_id
                        ? formik.errors.provider_id
                        : undefined
                    }
                    options={providersList.map(mapForListItems)}
                  />
                )}
                <ValidatedInput
                  label="Provider Date"
                  size="col-sm-3"
                  type="date"
                  field={formik.getFieldProps("provider_date")}
                  form={formik}
                />

                <SearchableDropdown
                  label="Currency"
                  apiCall={undefined}
                  search_key=""
                  value_name="cc"
                  label_name="name"
                  size="col-sm-4"
                  value={formik.values.currency_id}
                  onChange={({ value }: any) => {
                    formik.setFieldValue("currency_id", value);
                    let currObj = currencies.find(
                      (ii: any) => ii.currency_id === value
                    );
                    if (currObj) {
                      formik.setFieldValue(
                        "cos_exch_rate",
                        currObj.currency_exch_rate
                      );
                      setProviAndCurrency({
                        ...proviAndCurrency,
                        currency: currObj.currency_name as string,
                      });
                    }
                  }}
                  preOptions={currencies.map((cuu: any) => {
                    return {
                      label: cuu.currency_name,
                      value: cuu.currency_id,
                    };
                  })}
                  onBlur={() => formik.setFieldTouched("currency_id", true)}
                  error={
                    formik.touched.currency_id && formik.errors.currency_id
                      ? formik.errors.currency_id
                      : undefined
                  }
                />

                <ValidatedInput
                  label="Exch. Rate"
                  size="col-sm-3"
                  field={formik.getFieldProps("cos_exch_rate")}
                  form={formik}
                />

                <ValidatedInput
                  label="Provider Reference"
                  size="col-sm-3"
                  field={formik.getFieldProps("cos_provider_reference")}
                  form={formik}
                />
                <div>
                  <label htmlFor="file">File</label>
                  <input
                    id="file"
                    name="file"
                    type="file"
                    onChange={handleFileChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.csv_file && formik.errors.csv_file ? (
                    <div>{formik.errors.csv_file}</div>
                  ) : null}
                </div>
                <SaveButton
                  type="submit"
                  title="Save"
                  size="col-sm-3"
                  loading={saving}
                  disabled={formik.isSubmitting || !formik.isValid}
                />
                {error ? (
                  <p style={{ color: "red" }}>An error have occured</p>
                ) : (
                  <></>
                )}
                {msg ? (
                  <p className="text-success">Successfully created </p>
                ) : (
                  <></>
                )}
              </RowWrapper>
            </form>
          )}
        </>
      )}
    </>
  );
};
