import { WorkFlowTypeType } from "../../../Settings/components/workflows/types/types.type";
import { WorkflowRequestType } from "../requests.type";
export const initialState: RequestViewInitialStateType = {
  request: {
    request_id: 0,
    request_title: "",
    request_description: "",
    organization_id: "",
    provider_id: "",
    subscription_id: "",
  },
  subscription: {}, //could not even be there!
  isLoading: true, //overall loading!!
  workflowType: null,
  isLoadingWorkflowType: false,
  error: "",
};

export interface RequestViewInitialStateType {
  request: WorkflowRequestType;
  subscription: { [x: string]: any };
  workflowType: null | WorkFlowTypeType;
  isLoadingWorkflowType: boolean;
  isLoading: boolean;
  error: string;
}
