import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../modules/Auth/core/_requests";

export class ExpenseCategoryApi {
  private url = "settings/expense-categories";
  private dashboardUrl = "reports/expenses";

  getDashboardData = async () => {
    let results = await httpGet(`${this.dashboardUrl}/dashboard`);
    return results;
  };
  createExpenseCategory = async (data: any) => {
    let results = await httpPost(this.url, data);
    return this.extractExpenseCategory(results);
  };

  extractExpenseCategory = (results: any) => {
    if (results.data && results.data.status) {
      return results.data.data;
    }
    return false;
  };

  //update ExpenseCategory
  updateExpenseCategory = async (id: number | string, data: any) => {
    let results = await httpPut(`${this.url}/${id}`, data);
    return results;
  };

  //Get all ExpenseCategory
  pullAllExpenseCategory = async (params?: string) => {
    let results = await httpGet(`${this.url}${params ? "?" + params : ""}`);
    return this.extractExpenseCategory(results);
  };

  //Get one ExpenseCategory
  getOneExpenseCategory = async (id: number | string) => {
    let results = await httpGet(`${this.url}/${id}`);
    return results;
  };

  //Get all ExpenseCategory
  deleteExpenseCategory = async (id: number | string) => {
    let results = await httpDelete(`${this.url}/${id}`);
    return results;
  };
}
