import { Navigate, Route, Routes, Outlet } from "react-router-dom";

import { PageLink, PageTitle } from "../../../_metronic/layout/core";

import { ProjectList } from "./components/project-list";

import { MyProjects } from "./components/my-projects";
import { SidebarMenuItem } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem";
import { SidebarMenuItemWithSub } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItemWithSub";

const innerRouteBreadCrumbs: Array<PageLink> = [
  {
    title: "Project Tracker",

    path: "/project-tracker/project-list",

    isSeparator: false,

    isActive: false,
  },

  {
    title: "",

    path: "",

    isSeparator: true,

    isActive: false,
  },
];

const ProjectTrackerPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="project-list"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Project List
              </PageTitle>
              <ProjectList />
            </>
          }
        />

        <Route
          path="my-projects"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                My Projects
              </PageTitle>
              <MyProjects />
            </>
          }
        />

        <Route
          index
          element={<Navigate to="/project-tracker/project-list" />}
        />
      </Route>
    </Routes>
  );
};

export default ProjectTrackerPage;

export const ProjectTrackerRoutes = () => (
  // <SidebarMenuItemWithSub
  //   to="/project-tracker"
  //   title="Project Tracker"
  //   fontIcon="bi-sticky"
  //   icon="parcel-tracking"
  // >
  //   <SidebarMenuItem
  //     to="/project-tracker/project-list"
  //     title="Project List"
  //     icon="credit-cart"
  //     fontIcon="bi-person"
  //   />

  //   <SidebarMenuItem
  //     to="/project-tracker/my-projects"
  //     title="My Projects"
  //     icon="credit-cart"
  //     fontIcon="bi-person"
  //   />
  // </SidebarMenuItemWithSub>
  <></>
);
