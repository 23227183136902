import { useState, useEffect } from "react";
export const withFetch = (Component: any, fetchFn: any) => (props: any) => {
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState<any>(null);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const fetch = async () => {
    try {
      setLoadingData(true);
      let results = await fetchFn();
      if (results) setData(results);
      setError(null);
    } catch (ex) {
      setError(ex);
      setData([]);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    if (!data.length) fetch();
  }, []);

  return (
    <Component data={data} error={error} {...props} loading={loadingData} />
  );
};
