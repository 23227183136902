export const MIS_BLUE: string = "#18b6ff";
export const DARKER_MIS_BLUE: string = "#0493d6";
export const MIS_DARK: string = "#121933";
export const MIS_WHITE: string = "#ffffff";

export const MIS_RED: string = "#ee0d08"; //afritrack red

export const MIS_DANGER: string = "#f64e60";
export const MIS_DANGER_LIGHT: string = "#ffe2e5";

export const MIS_SUCCESS: string = "#17C653"; //"#1bc5bd";
export const MIS_SUCCESS_LIGHT: string = "#DFFFEA"; //"#c9f7f5";

export const MIS_WARNING: string = "#ffa800";
export const MIS_WARNING_LIGHT: string = "#fff4de";

export const MIS_MUTED: string = "#e1e3ea";
export const MIS_BLACK: string = "#000000";
export const NAVBAR_BG_COLOR = MIS_BLUE;
export const ICONS_MIS_BLUE = MIS_BLUE;

//Logo names
/**
 * This works when we have place the required logo inside /public/media/logos folder
 */

export const LOGO_FULL_BLACK_AND_WHITE = "mis_logo_black_n_white.png";
export const LOGO_BLACK_AND_WHITE_SQUARES =
  "mis_logo_black_n_white_squares.png";

export const APP_NAME = "TrackMIS"