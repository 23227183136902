/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { ResetPassword } from "./components/ResetPassword";
import ParticlesComponent from "./AnimatedBackground";
import "./animated.bg.css";
const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add("bg-body");
    return () => {
      document.body.classList.remove("bg-body");
    };
  }, []);

  return (
    <div
      className="d-flex flex-column flex-column-fluid position-relative"
      //className='bgi-size-contain'
      // style={{
      //   backgroundImage: `url(${toAbsoluteUrl("/media/stock/network.jpg")})`,
      // }}
    >
      <ParticlesComponent id="particles" />
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-5 pb-lg-20">
        {/* begin::Wrapper */}
        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto bg-opacity-100 bg-light">
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className="d-flex flex-center flex-column-auto p-5">
        <div className="d-flex align-items-center fw-bold fs-6"></div>
      </div>
      {/* end::Footer */}
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/:token" element={<ResetPassword />} />

      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
