import { FC } from "react";
import { NormalDate } from "../../../../general-components/forms/date-element";
import { COSVIEWURLMapper as URLMapper } from "./cos.type";
import { SpinnerSm } from "../../../../general-components/elements/spinners";
import { NormalInput } from "../../../../general-components/forms/input-element";
import { FilterAsset } from "../../../Contacts/components/assets/utils/filter-asset";

export const CosFilterView: FC<{
  searchParams: any;
  handleQueryChange: any;
  updateQueryString: any;
  isLoading: boolean;
}> = ({ searchParams, handleQueryChange, updateQueryString, isLoading }) => {
  return (
    <div className="row mx-3 mt-3">
      <NormalDate
        label="Date From"
        value={searchParams.get(URLMapper["date_from"]) as string}
        onChange={(e: any) => handleQueryChange("date_from", e.target.value)}
      />
      <NormalDate
        label="Date To"
        value={searchParams.get(URLMapper["date_to"]) as string}
        onChange={(e: any) => handleQueryChange("date_to", e.target.value)}
      />
      <NormalInput
        label="Identification"
        value={searchParams.get(URLMapper["cosi_identification"]) as string}
        onChange={(e: any) =>
          handleQueryChange("cosi_identification", e.target.value)
        }
      />

      <FilterAsset
        label="Asset:"
        asset_id={searchParams.get(URLMapper["asset_id"]) as string}
        size="col-sm-4 mt-2"
        handleChange={handleQueryChange}
        data_holder="asset_id"
      />

      <div className="col-sm-3 mt-2">
        <span className="fs-7 text-dark fw-bold ms-1">Flag Status: </span>
        <select
          className="form-select form-select-sm "
          onChange={(e: any) => {
            handleQueryChange("cosi_flag_status", e.target.value);
          }}
          aria-label=""
          defaultValue={
            searchParams.get(URLMapper["cosi_flag_status"]) as string
          }
        >
          <option selected value="">
            All
          </option>
          <option value="OK">OK</option>
          <option value="MISMATCH">MISMATCH</option>
          <option value="SUSPENDED">SUSPENDED</option>
          <option value="UNKNOWN">UNKNOWN</option>
        </select>
      </div>

      <div className="col-sm-3 mt-2">
        <span className="fs-7 text-dark fw-bold ms-1">
          Subscription Status:{" "}
        </span>
        <select
          className="form-select form-select-sm "
          onChange={(e: any) => {
            handleQueryChange("subscription_status", e.target.value);
          }}
          aria-label=""
          defaultValue={
            searchParams.get(URLMapper["subscription_status"]) as string
          }
        >
          <option selected value="">
            All
          </option>
          <option value="Pending">Pending</option>
          <option value="Active">Active</option>
          <option value="Suspended">Suspended</option>
          <option value="Expired">Expired</option>
          <option value="Terminated">Terminated</option>
          <option value="Restricted">Restricted</option>
        </select>
      </div>
      <div className="col-sm-3 mt-2">
        <span className="fs-7 text-dark fw-bold ms-1">Sort By: </span>
        <select
          className="form-select form-select-sm "
          onChange={(e: any) => {
            handleQueryChange("sort_by", e.target.value);
          }}
          aria-label=""
          defaultValue={searchParams.get(URLMapper["sort_by"]) as string}
        >
          <option selected value="">
            All
          </option>
          <option value="Subscription">Subscription</option>
          <option value="Identification">Identification</option>
        </select>
      </div>

      <div className="col-sm-3 mt-2">
        <span className="fs-7 text-dark fw-bold ms-1">Sort Order: </span>
        <select
          className="form-select form-select-sm "
          onChange={(e: any) => {
            handleQueryChange("sort_order", e.target.value);
          }}
          aria-label=""
          defaultValue={searchParams.get(URLMapper["sort_order"]) as string}
        >
          <option selected value="">
            All
          </option>
          <option value="Ascending">Ascending</option>
          <option value="Descending">Descending</option>
        </select>
      </div>
      {/* <NormalSelect
        label="Ticket Type"
        items={["--Select Ticket Type--", "Cost of Service", "General Ticket"]}
      /> */}

      {/* <NormalSelect label="SLA Check" items={["Any", "OK", "Fail"]} /> */}
      <div className="col-sm-2 mt-2">
        <button
          className="btn btn-sm btn-success mt-6"
          onClick={(e) => {
            e.preventDefault();
            updateQueryString();
          }}
          disabled={isLoading}
        >
          <SpinnerSm loading={isLoading} label="Filter" />
        </button>
      </div>
    </div>
  );
};
