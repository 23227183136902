import axios from "axios";
import { useNavigate } from "react-router-dom";
const useHttpInterceptor = (logout: () => void) => {
  const navigate = useNavigate();
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        /**logout the use called right here */
        logout();
        navigate("/auth/login", { replace: true });
      }
      return Promise.reject(error);
    }
  );
};

export default useHttpInterceptor;
