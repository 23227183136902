import {
  ChangeEvent,
  Dispatch,
  FC,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { isPlainObject } from "../../utils/general-utils";
import AsyncSelect from "react-select/async";
import { OptionsOrGroups, GroupBase } from "react-select";
import { normalizePagination } from "../paginator";
import { MIS_DANGER } from "../../../_metronic/mis.themes";
import { debounce, DEBOUNCE_TIME } from "../../apis/api-utils/debounce";
import { OrganizationApi } from "../../apis/organizationApi";
import { BillsApi } from "../../apis/billsApi";
import { ProductsApis } from "../../apis/productsApi";
import { TaxRatesApi } from "../../apis/taxRatesApi";
import { ExpenseCategoryApi } from "../../apis/expenseCategoryApi";
import { ValidatedSelectInput } from "./input-element";
import { withFetch } from "../../higher-order-components/with-fetch";
import { countriesList } from "../../utils/countries";

const OrgApi = new OrganizationApi();
const productApi = new ProductsApis();
const ExpCategoryApi = new ExpenseCategoryApi();
const taxRatesApi = new TaxRatesApi();
const billsApi = new BillsApi();
type SelectPropType = {
  label: string;
  items: any[];
  size?: string;
  marginTop?: string;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  placeholder?: string;
  key_name?: string;
  value_name?: string;
  id_name?: string;
};
export const NormalSelect = forwardRef<HTMLSelectElement, SelectPropType>(
  (
    {
      label,
      items,
      size,
      marginTop,
      onChange,
      value,
      placeholder,
      key_name,
      value_name,
      id_name,
    }: SelectPropType,
    ref
  ) => {
    const selectRef = useRef<HTMLSelectElement>(null);

    //Conditionally use useImperativeHandle only if a ref is passed

    useImperativeHandle(ref, () => selectRef.current as HTMLSelectElement);

    return (
      <div className={`${size || "col-sm-2"} ${marginTop || "mt-2"}`}>
        <span className="fs-7 text-dark fw-bold ms-1">{label}: </span>
        <select
          className="form-select form-select-sm"
          aria-label=""
          onChange={onChange}
          value={value}
          ref={selectRef}
        >
          {placeholder ? <option value="">{placeholder}</option> : null}
          {items.map((item: any) => {
            if (isPlainObject(item)) {
              return (
                <option
                  value={key_name ? item[key_name] : item.id}
                  key={id_name ? item[id_name] : item.id}
                  // selected={key_name ? item[key_name] : item.id === value}
                >
                  {value_name ? item[value_name] : item.name}
                </option>
              );
            }
            return (
              <option value={item} key={item}>
                {item}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
);

export const NormalSelectWithData = ({
  label,
  size,
  marginTop,
  onChange,
  value,
  error,
  loading,
  data,
  value_name,
  key_name,
  id_name,
  placeholder,
}: SelectPropType & {
  error: any;
  loading: boolean;
  data: any;
  value_name: any;
  key_name: any;
  id_name: any;
}) => {
  return (
    <div className={`${size || "col-sm-2"} ${marginTop || "mt-2"}`}>
      <span className="fs-7 text-dark fw-bold ms-1">{label}: </span>
      {loading ? (
        <p>loading...</p>
      ) : (
        <select
          className="form-select form-select-sm"
          aria-label=""
          onChange={onChange}
          value={value}
        >
          {placeholder ? <option value="">{placeholder}</option> : null}
          {data.map((item: any) => (
            <option
              value={key_name ? item[key_name] : item.id}
              key={id_name ? item[id_name] : item.id}
            >
              {value_name ? item[value_name] : item.name}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

interface Option {
  data?: any;
  value: string;
  label: string;
}
interface Props {
  apiCall: any;
  search_key: string;
  value_name: string;
  label_name: string | ((data: any) => string);
  pre_filter_string?: string;
  value?: any;
  onChange?: any;
  label?: string;
  size?: string;
  marginTop?: string;
  onBlur?: any;
  error?: any;
  preOptions?: Option[];
  urlsegments?: string;
  suggestedValue?: any;
  suggestedKey?: any;
  hideLabel?: boolean; //if you dont want the label to be shown
  isDisabled?: boolean;
  giveSelectedObject?: (data: any, selected: any) => void;
  omit?: (data: any) => boolean;
  labelAlign?: "start" | "end" | "center";
  fetcher?: (
    updater: (value: boolean) => void
  ) => Promise<{ value: string; label: string } | undefined | null>;
}

export const SearchableDropdown: React.FC<Props> = ({
  apiCall, //this is a function to be called on the search
  search_key,
  value_name,
  label_name,
  value,
  onChange,
  label,
  size,
  marginTop,
  onBlur,
  error,
  preOptions,
  suggestedValue,
  pre_filter_string,
  labelAlign,
  isDisabled,
  giveSelectedObject,
  omit, //receive function so we specify thing we dont want to see on our list from remote source
  /**
   * - fetchter -
   * its a callback function which is called when we're doing rendering
   * and basically it will fetch the backend and give back only the required record in the formart of {value:string,label : string}
   * => fetcher receives a function setIsLoading.... and that needed to update the loading state of the component
   *    to give users feedback
   * => will be updating the records on isLoading from false / true and viceversa
   * => gives back a record of either {value:string;label:string} or just a null/undefined value
   *
   */
  fetcher,
  hideLabel,
}) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    suggestedValue || null
  );
  const [apiError, setApiError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allData, setAllData] = useState<any>();

  const debouncedSearchAPI = debounce(apiCall, DEBOUNCE_TIME);

  const loadOptions = (inputValue: string) => {
    return new Promise<OptionsOrGroups<Option, GroupBase<Option>>>(
      async (resolve) => {
        let options: Option[] = [];
        if (preOptions) {
          const filteredOptions = preOptions.filter((option) =>
            inputValue.trim()
              ? option.label.toLowerCase().includes(inputValue.toLowerCase())
              : true
          );
          return resolve(filteredOptions);
        } else {
          try {
            const response = await debouncedSearchAPI(
              `${
                pre_filter_string ? pre_filter_string + "&" : ""
              }${search_key}=${inputValue}`
            );
            // setAllData(response);
            normalizePagination(
              response,
              (data: any) => {
                setAllData(data);

                options = [
                  {
                    value: "",
                    label: "--",
                  },
                  ...data
                    .filter((dd: any) => {
                      if (!omit) return true;
                      return omit(dd);
                    })
                    .map((item: any) => ({
                      value: item[value_name],
                      label:
                        typeof label_name === "string"
                          ? item[label_name]
                          : label_name(item),
                    })),
                ];
              },
              () => {}
            );

            resolve(options);
          } catch (error) {
            console.error("Error fetching data:", error);
            setApiError("Error fetching data");
            resolve([]);
          }
        }
      }
    );
  };

  useEffect(() => {
    (async () => {
      //will call wont call i dont know but its safe
      if (fetcher) {
        let results = await fetcher(setIsLoading);
        if (results) {
          setSelectedOption(results as Option);
        }
      }
    })();
  }, []);
  return (
    <div className={`${size || "col-sm-2"} ${marginTop ? marginTop : "mt-2"}`}>
      {!hideLabel ? (
        <span className={`fs-7 text-dark fw-bold ms-1 text-${labelAlign}`}>
          {label}{" "}
        </span>
      ) : null}
      <AsyncSelect
        isLoading={isLoading}
        value={selectedOption}
        onChange={(val: any) => {
          onChange(val);
          setSelectedOption(val);
          if (allData && giveSelectedObject) {
            giveSelectedObject(allData, val);
          }
        }}
        isDisabled={isDisabled}
        loadOptions={loadOptions}
        options={preOptions}
        cacheOptions
        placeholder="Type to search..."
        onBlur={onBlur}
      />
      {error || apiError ? (
        <p style={{ color: MIS_DANGER }}>{error || apiError}</p>
      ) : null}
    </div>
  );
};

export default SearchableDropdown;

export type SearchableProps = {
  onChange?: (value: any) => void;
  formik?: any;
  css?: string;
  pickedData?: (value: any) => void;
  label?: string;
  pre_filter_string?: string;
  isDisabled?: boolean;
};

export const SearchableOrganization: FC<
  SearchableProps & { invoke_fetcher?: boolean }
> = ({
  onChange,
  formik,
  css,
  pickedData,
  label,
  pre_filter_string,
  isDisabled,
  invoke_fetcher,
}) => {
  return (
    <SearchableDropdown
      label={`${label || "Organizations"}`}
      apiCall={OrgApi.pullAllOrganization}
      search_key="organization_name"
      label_name="organization_name"
      value_name="organization_id"
      pre_filter_string={pre_filter_string}
      isDisabled={isDisabled}
      size={`${css ? css : "col-sm-3"}`}
      marginTop="0"
      onChange={(value: any) => {
        formik.setFieldValue("organization_id", value?.value);
        if (onChange) {
          onChange(value);
        }
      }}
      onBlur={() => formik.setFieldTouched("organization_id", true)}
      error={
        formik.touched.organization_id && formik.errors.organization_id
          ? formik.errors.organization_id
          : undefined
      }
      fetcher={async (setWaitingState) => {
        if (!invoke_fetcher || !formik.values.organization_id) return null;
        //set state must be there
        setWaitingState(true);
        let results = await OrgApi.getOneOrganization(
          formik.values.organization_id as string
        );
        setWaitingState(false);
        if (results && results.organization_id && results.organization_name) {
          return {
            value: results.organization_id,
            label: results.organization_name,
          };
        }
        return null;
      }}
      // to read
      giveSelectedObject={(data: any, selected: any) => {
        // console.log(data);//suppossed to be an array
        if (pickedData) {
          pickedData({ data, selected });
        }
      }}
    />
  );
};

export const SearchableCategory: FC<SearchableProps> = ({
  onChange,
  formik,
  css,
  pickedData,
}) => {
  return (
    <SearchableDropdown
      label="Category:"
      apiCall={productApi.pullAllProductCategories}
      search_key="category_name"
      label_name={(data) => {
        return `${data.category_name}`;
      }}
      value_name="category_id"
      size={`${css ? css : "col-sm-3"}`}
      marginTop="5px"
      onChange={(value: any) => {
        formik.setFieldValue("category_id", value?.value);
        if (onChange) {
          onChange(value);
        }
      }}
      onBlur={() => {
        formik.setFieldTouched("category_id", true);
      }}
      error={
        formik.touched.category_id && formik.errors.category_id
          ? formik.errors.category_id
          : undefined
      }
      // to read
      giveSelectedObject={(data: any, selected: any) => {
        // console.log(data);//suppossed to be an array
        if (pickedData) {
          pickedData({ data, selected });
        }
      }}
    />
  );
};

export const SearchableProductsAndServices: FC<
  Partial<SearchableProps> & {
    data_holder: string;
    label?: string;
    invoke_fetcher?: boolean;
    suggestedValue?: { value: string | number; label: string };
  }
> = ({
  formik,
  pickedData,
  onChange,
  data_holder,
  css,
  label,
  invoke_fetcher,
  suggestedValue,
  pre_filter_string,
}) => {
  const [_, setRenderCount] = useState(0);
  useEffect(() => {
    setRenderCount((prev) => prev + 1);
  }, [suggestedValue]);
  return (
    <SearchableDropdown
      label={label ? label : "Product:"}
      apiCall={productApi.pullAllProductServices}
      pre_filter_string={pre_filter_string || "ps_invoiceable=Yes"}
      search_key="ps_name"
      label_name={(data) => {
        return `${data.category_name}: ${data.ps_name}`;
      }}
      value_name="ps_id"
      size={`${css ? css : "col-sm-3"}`}
      marginTop="0"
      onChange={({ value }: any) => {
        if (formik) {
          formik.setFieldValue(data_holder, value);
        }
        if (onChange) {
          onChange(value);
        }
      }}
      suggestedValue={suggestedValue}
      fetcher={async (setWaitingState) => {
        if (!invoke_fetcher) return null;
        //set state must be there
        setWaitingState(true);
        let results = formik
          ? await productApi.getOneProductServices(
              formik.values[data_holder] as string
            )
          : suggestedValue?.value
          ? await productApi.getOneProductServices(
              suggestedValue?.value as string
            )
          : null;
        setWaitingState(false);
        if (results && results?.product?.ps_id && results?.product?.ps_id) {
          return {
            value: results?.product?.ps_id,
            label: results?.product?.ps_name,
          };
        }
        return null;
      }}
      onBlur={() => {
        if (formik) {
          formik.setFieldTouched(data_holder, true);
        }
      }}
      error={
        formik
          ? formik.touched[data_holder] && formik.errors[data_holder]
            ? formik.errors[data_holder]
            : undefined
          : undefined
      }
      // to read
      giveSelectedObject={(data: any, selected: any) => {
        // console.log(data);//suppossed to be an array
        // if (Array.isArray(data) && selected) {
        //   setPickedItem(data.find((dt) => dt.ps_id === selected.value));
        // }
        if (pickedData) {
          pickedData({ data, selected });
        }
      }}
    />
  );
};

//=============== location select tool ===============

const LocationSelection: FC<
  SearchableProps & {
    data?: any;
    data_holder: string;
    loading?: boolean;
    error?: string;
    preselected_id?: string;
    hideLabel?: boolean;
  }
> = ({
  onChange,
  css,
  formik,
  data,
  loading,
  error,
  data_holder,
  preselected_id,
  hideLabel,
}) => {
  if (loading) return <span>Loading...</span>;

  return (
    <ValidatedSelectInput
      hideLabel={hideLabel}
      label="Location"
      value={formik ? formik.values[data_holder] : ""}
      size={`${css ? css : "col-sm-3"}`}
      onChange={(value: any) => {
        formik && formik.setFieldValue(data_holder, value);
        if (onChange) {
          onChange(value);
        }
      }}
      preselected_id={preselected_id}
      onBlur={() => {
        formik && formik.setFieldTouched(data_holder, true);
      }}
      error={
        formik && formik.touched[data_holder] && formik.errors[data_holder]
          ? formik.errors[data_holder]
          : undefined
      }
      options={data.map((dt: any) => {
        return { label: dt.location_name, value: dt.location_id };
      })}
    />
  );
};

export const LocationInput = withFetch(
  LocationSelection,
  productApi.pullAllLocations
);

export const SearchableExpenseCategory: FC<
  Partial<SearchableProps> & {
    data_holder: string;
    label?: string;
  }
> = ({ onChange, formik, css, pickedData, data_holder, label }) => {
  return (
    <SearchableDropdown
      label={`${label ? label : "Expense Category:"}`}
      apiCall={ExpCategoryApi.pullAllExpenseCategory}
      search_key="ec_name"
      label_name={(data) => {
        return `${data.ec_name}`;
      }}
      value_name="ec_id"
      size={`${css ? css : "col-sm-3"}`}
      marginTop="0"
      onChange={(value: any) => {
        if (formik) {
          formik.setFieldValue(data_holder, value?.value);
        }
        if (onChange) {
          onChange(value);
        }
      }}
      onBlur={() => {
        if (formik) {
          formik.setFieldTouched(data_holder, true);
        }
      }}
      error={
        formik
          ? formik.touched[data_holder] && formik.errors[data_holder]
            ? formik.errors[data_holder]
            : undefined
          : undefined
      }
      giveSelectedObject={(data: any, selected: any) => {
        // console.log(data);//suppossed to be an array
        if (pickedData) {
          pickedData({ data, selected });
        }
      }}
    />
  );
};

export const ExpenseCategoryFilter: FC<
  Partial<SearchableProps> & {
    data_holder: string;
    label?: string;
  }
> = ({ onChange, formik, css, pickedData, data_holder, label }) => {
  return (
    <SearchableDropdown
      label={`${label ? label : "Expense Category:"}`}
      apiCall={ExpCategoryApi.pullAllExpenseCategory}
      search_key="ec_name"
      label_name={(data) => {
        return `${data.ec_name}`;
      }}
      value_name="ec_id"
      size={`${css ? css : "col-sm-3"}`}
      marginTop="0"
      onChange={(value: any) => {
        if (formik) {
          formik.setFieldValue(data_holder, value?.value);
        }
        if (onChange) {
          onChange(value);
        }
      }}
      onBlur={() => {
        if (formik) {
          formik.setFieldTouched(data_holder, true);
        }
      }}
      error={
        formik
          ? formik.touched[data_holder] && formik.errors[data_holder]
            ? formik.errors[data_holder]
            : undefined
          : undefined
      }
      giveSelectedObject={(data: any, selected: any) => {
        // console.log(data);//suppossed to be an array
        if (pickedData) {
          pickedData({ data, selected });
        }
      }}
    />
  );
};

export const UnBiasedSelect: FC<{
  label: string;
  name: string;
  data_holder: string;
  url?: string;
  onChange?: any;
  formik?: any;
  css?: string;
  data?: any;
  error?: any;
  loading?: boolean;
  pickedData?: any;
  label_name: string | ((data: any) => string);
  value_name: string;
  fs?: string;
}> = ({
  label,
  name,
  data,
  data_holder,
  onChange,
  formik,
  css,
  label_name,
  loading,
  error,
  value_name,
  fs, //font-size
}) => {
  if (!data) return <span style={{ color: MIS_DANGER }}>Error</span>;
  if (loading) return <span>Loading...</span>;
  return (
    <div className={`${css ? css : "col-sm-3"}`}>
      <label htmlFor={name} className={`${fs ? fs : ""} form-label fs-7 mb-0`}>
        {label}
      </label>
      <select
        className="form-select form-select-sm"
        defaultValue={formik ? formik.values[data_holder] : ""}
        name={name}
        onChange={(e: any) => {
          if (formik) {
            formik.setFieldValue(data_holder, e.target.value);
          }
          if (onChange) {
            onChange(
              data.find((ee: any) => ee.tax_id === parseInt(e.target.value))
            );
          }
        }}
        onBlur={() => {
          if (formik) {
            formik.setFieldTouched(data_holder, true);
          }
        }}
      >
        <option value="">Select</option>
        {data && data.length
          ? data.map((element: any, index: number) => {
              if (typeof label_name === "string") {
                return (
                  <option key={index} value={element[value_name]}>
                    {element[label_name]}
                  </option>
                );
              }

              if (typeof label_name === "function") {
                return (
                  <option key={index} value={element[value_name]}>
                    {label_name(element)}
                  </option>
                );
              }
              return [];
            })
          : []}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export const TaxRateSelect = withFetch(UnBiasedSelect, async () => {
  let results: any = [];
  try {
    results = await taxRatesApi.pullAllTaxRates();
    results = taxRatesApi.extractRates(results);
    return results;
  } catch (ex) {}
  return results;
});

// ==================== SEARCHABLE COUNTRIES ==========================

export const SearchableCountry: FC<
  Partial<SearchableProps> & {
    data_holder: string;
    label?: string;
  }
> = ({ onChange, formik, css, pickedData, data_holder, label }) => {
  return (
    <SearchableDropdown
      label={`${label ? label : "Country:"}`}
      apiCall={null}
      search_key=""
      label_name="label"
      value_name="value"
      size={`${css ? css : "col-sm-3"}`}
      marginTop="0"
      // value={formik ? formik.values[data_holder] : undefined}
      preOptions={countriesList.map((country: string) => {
        return { label: country, value: country };
      })}
      onChange={(value: any) => {
        if (formik) {
          formik.setFieldValue(data_holder, value?.value);
        }
        if (onChange) {
          onChange(value);
        }
      }}
      onBlur={() => {
        if (formik) {
          formik.setFieldTouched(data_holder, true);
        }
      }}
      error={
        formik
          ? formik.touched[data_holder] && formik.errors[data_holder]
            ? formik.errors[data_holder]
            : undefined
          : undefined
      }
      giveSelectedObject={(data: any, selected: any) => {
        // console.log(data);//suppossed to be an array
        if (pickedData) {
          pickedData({ data, selected });
        }
      }}
    />
  );
};

export const SearchableCountries: FC<
  Partial<SearchableProps> & {
    data_holder: string;
    label?: string;
  }
> = ({ onChange, formik, css, pickedData, data_holder, label }) => {
  return (
    <SearchableDropdown
      label={`${label ? label : "Currency:"}`}
      apiCall={null}
      search_key=""
      label_name="label"
      value_name="value"
      size={`${css ? css : "col-sm-3"}`}
      marginTop="0"
      // value={formik ? formik.values[data_holder] : undefined}
      preOptions={countriesList.map((country: string) => {
        return { label: country, value: country };
      })}
      onChange={(value: any) => {
        if (formik) {
          formik.setFieldValue(data_holder, value?.value);
        }
        if (onChange) {
          onChange(value);
        }
      }}
      onBlur={() => {
        if (formik) {
          formik.setFieldTouched(data_holder, true);
        }
      }}
      error={
        formik
          ? formik.touched[data_holder] && formik.errors[data_holder]
            ? formik.errors[data_holder]
            : undefined
          : undefined
      }
      giveSelectedObject={(data: any, selected: any) => {
        // console.log(data);//suppossed to be an array
        if (pickedData) {
          pickedData({ data, selected });
        }
      }}
    />
  );
};

export const SearchableBills: FC<
  SearchableProps & {
    data_holder?: string;
    optionlabel?: (data: any) => string;
  }
> = ({
  onChange,
  formik,
  css,
  pickedData,
  label,
  data_holder,
  optionlabel,
  pre_filter_string,
  isDisabled,
}) => {
  return (
    <SearchableDropdown
      label={`${label || "Bills"}`}
      apiCall={async (params?: string) => {
        let results = await billsApi.pullAllBills(params);
        return billsApi.extractBills(results);
      }}
      search_key="sb_id"
      label_name={optionlabel || "sb_id"}
      value_name="sb_id"
      size={`${css ? css : "col-sm-3"}`}
      pre_filter_string={pre_filter_string}
      marginTop="0"
      isDisabled={isDisabled}
      onChange={(value: any) => {
        if (formik) {
          formik.setFieldValue(data_holder || "sb_id", value?.value);
        }
        if (onChange) {
          onChange(value);
        }
      }}
      onBlur={() => {
        if (formik) {
          formik.setFieldTouched(data_holder || "sb_id", true);
        }
      }}
      error={
        formik
          ? formik.touched[data_holder || "sb_id"] &&
            formik.errors[data_holder || "sb_id"]
            ? formik.errors[data_holder || "sb_id"]
            : undefined
          : null
      }
      // to read
      giveSelectedObject={(data: any, selected: any) => {
        // console.log(data);//suppossed to be an array
        if (pickedData) {
          pickedData({ data, selected });
        }
      }}
    />
  );
};

export const SearchableBillItems: FC<
  SearchableProps & {
    data_holder?: string;
    optionlabel?: (data: any) => string;
  }
> = ({
  onChange,
  formik,
  css,
  pickedData,
  label,
  data_holder,
  optionlabel,
  pre_filter_string,
  isDisabled,
}) => {
  return (
    <SearchableDropdown
      label={`${label || "Bills"}`}
      apiCall={async (params?: string) => {
        if (!params || !params.trim()) return;
        let splitResults = params?.split("&sb_id=");

        let results = await billsApi.getBillItems(
          splitResults[1].trim() || "",
          splitResults[0].trim() || ""
        );
        return billsApi.extractBills(results);
      }}
      search_key={data_holder || "sb_id"}
      label_name={optionlabel || "sb_id"}
      value_name={data_holder || "sb_id"}
      size={`${css ? css : "col-sm-3"}`}
      pre_filter_string={pre_filter_string}
      marginTop="0"
      isDisabled={isDisabled}
      onChange={(value: any) => {
        if (formik) {
          formik.setFieldValue(data_holder || "sb_id", value?.value);
        }
        if (onChange) {
          onChange(value);
        }
      }}
      onBlur={() => {
        if (formik) {
          formik.setFieldTouched(data_holder || "sb_id", true);
        }
      }}
      error={
        formik
          ? formik.touched[data_holder || "sb_id"] &&
            formik.errors[data_holder || "sb_id"]
            ? formik.errors[data_holder || "sb_id"]
            : undefined
          : null
      }
      // to read
      giveSelectedObject={(data: any, selected: any) => {
        // console.log(data);//suppossed to be an array
        if (pickedData) {
          pickedData({ data, selected });
        }
      }}
    />
  );
};

type PreOrganization = {
  label: string;
  value: string; //this is an id
};
export const EditOrganization: FC<{
  organization: PreOrganization;
  formik: any;
  css?: string;
}> = ({ organization, formik, css }) => {
  return (
    <SearchableDropdown
      label="Organization:"
      apiCall={OrgApi.pullAllOrganization}
      search_key="organization_name"
      label_name="organization_name"
      value_name="organization_id"
      size={`${css ? css : "col-sm-6 mt-2"}`}
      marginTop="0"
      // suggestedValue={{
      //   label: data?.RequisitionData.organization_name,
      //   value: data?.RequisitionData.organization_id,
      // }}

      suggestedValue={organization}
      onChange={(value: any) => {
        formik.setFieldValue("organization_id", value?.value);
      }}
      onBlur={() => formik.setFieldTouched("organization_id", true)}
      error={
        formik.touched.organization_id && formik.errors.organization_id
          ? formik.errors.organization_id
          : undefined
      }
    />
  );
};
