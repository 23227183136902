import { FC } from "react";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { MIS_SUCCESS, MIS_SUCCESS_LIGHT } from "../../../_metronic/mis.themes";

interface Props {
  size?: number;
  first_word?: string;
  second_word?: string;
  path?: string;
  bgColor?: string;
  color?: string;
  colorRaw?: boolean;
  square?: boolean;
  shadow?: boolean;
  fullWord?: string;
}
export const UserPhoto: FC<Props> = ({
  size,
  first_word,
  second_word,
  path,
  bgColor,
  color,
  colorRaw,
  square,
  shadow,
  fullWord,
}) => {
  return (
    <>
      {path ? (
        <img
          src={toAbsoluteUrl(path)}
          alt=""
          // width={size ? size + "rem" : 5 + "rem"}
          style={{
            maxWidth: size ? size + "rem" : 5 + "rem",
            borderRadius: square ? 8 + "px" : 50 + "%",
          }}
        />
      ) : (
        <div
          style={{
            width: size ? size + "rem" : 5 + "rem",
            height: size ? size + "rem" : 5 + "rem",
            borderRadius: square ? 8 + "px" : 50 + "%",
            backgroundColor: bgColor
              ? colorRaw
                ? bgColor
                : undefined
              : MIS_SUCCESS_LIGHT,
            color: color ? (colorRaw ? color : undefined) : MIS_SUCCESS,
          }}
          className={`d-flex justify-content-center align-items-center ${
            shadow ? "shadow" : ""
          } ${bgColor && !colorRaw ? bgColor : ""}`}
        >
          {!fullWord ? (
            <span
              className={`fw-bolder ${color && !colorRaw ? color : ""}`}
              style={{ fontSize: `${size ? (size * 1.8) / 5 : 1.8}rem` }}
            >
              {first_word && first_word[0]}
              {second_word && second_word[0]}
            </span>
          ) : (
            <span
              className={`fw-bolder ${color && !colorRaw ? color : ""}`}
              style={{ fontSize: `${size ? (size * 1.8) / 5 : 1.8}rem` }}
            >
              {fullWord}
            </span>
          )}
        </div>
      )}
    </>
  );
};
