/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
// import { useIntl } from "react-intl";
import { KTIcon, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { PageTitle } from "../../../../_metronic/layout/core";

// import axios from "axios";
import { ShadowedCard } from "../../../general-components/panels/shadowed-card";
import { CrumbHeader } from "../../../general-components/headings/crumb-header";
import { HorizontalApart } from "../../../general-components/simple-layouts/horizontal-apart";
// import { CollapsibleGroupButtons } from "../../../general-components/elements/group-buttons";
// import { BASE_URL } from "../../Profile/components/constants";
import { OverviewDashboardApis } from "../../../apis/mainDashboardApi";
import PieChartReusable from "../../Reports/usables/usable-amchart-pie-chart";
import { FaintedTitle } from "../../../general-components/elements/fainted-title";
import { LoadingVerticalBars } from "../../../general-components/loaders/simple-loader";
import { SmoothLineCurveChart } from "../../Reports/usables/apex-smooth-linechart";
import { CardsWidget21 } from "../../../../_metronic/partials/widgets";
import { ColoredList } from "../../../general-components/lists/colored-list";
import { k_m_formatNumber } from "../../../utils/number-utils";
import { APP_NAME } from "../../../../_metronic/mis.themes";

const API = new OverviewDashboardApis();

type RequestType = {
  request_id: number | string;
  request_date_created: string;
  wft_name: string;
  request_title: string;
  request_description: string;
  request_status: string;
};
type dataType = {
  active_customers_count: { active_customers: number }[];
  new_customers_past_six_months: {
    stats_month: string;
    stats_value: number | string;
  }[];
  sales_past_six_months: {
    sales_period: string;
    sales_value: string | number;
  }[];
  sales_from_invoice_past_six_months: {
    report_month: string;
    report_value: string | number;
  }[];
  six_months_collection_trend: {
    report_period: string;
    report_value: string | number;
  }[];
  sales_this_year: { stats_value: number | string }[];
  collection_this_year: { stats_value: number | string }[];
  this_year_expenses: { report_value: number | string }[];
  this_year_bills: { report_value: number | string }[];
  six_months_bills_trend: {
    report_period: string;
    report_value: string | number;
  }[];
  six_months_expense_trend: {
    report_period: string;
    report_value: string | number;
  }[];
  this_year_subscription: { report_count: number | string }[];
  six_months_subscription_trend: {
    report_period: string;
    report_value: string | number;
  }[];
  latest_requests: RequestType[];
  top_ten_products_this_year: {
    report_category: string;
    report_value: string | number;
  }[];
};

const initialData: dataType = {
  active_customers_count: [{ active_customers: 0 }],
  new_customers_past_six_months: [],
  sales_past_six_months: [],
  sales_this_year: [{ stats_value: "0" }],
  collection_this_year: [{ stats_value: "0" }],
  six_months_collection_trend: [],
  sales_from_invoice_past_six_months: [],
  this_year_expenses: [],
  six_months_expense_trend: [],
  six_months_bills_trend: [],
  this_year_bills: [],
  this_year_subscription: [],
  six_months_subscription_trend: [],
  latest_requests: [],
  top_ten_products_this_year: [],
};
const Overview: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<dataType>(initialData);

  document.title = `Main Dashboard | ${APP_NAME}`;

  const loadStats = async () => {
    try {
      setIsLoading(true);

      let results = await API.overview();
      console.log(results);
      console.log(results.data.stats);
      if (results.data.status) {
        setData(results.data.stats);
      }

      //test baseurl
      // await axios.get(`${BASE_URL}settings/view`);
      // console.log(res.data);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadStats();
  }, []);
  if (isLoading) {
    return (
      <div className="d-flex">
        <LoadingVerticalBars />
      </div>
    );
  }
  return (
    <>
      <div>
        <ShadowedCard>
          <HorizontalApart>
            <CrumbHeader fromPosition="Dashboard" url="#" title="Overview" />
          </HorizontalApart>
        </ShadowedCard>
      </div>

      <div className="row px-0 g-4 g-xl-10s mb-5 mb-xl-10  ">
        <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-5 mb-xl-10 ">
          <SmoothLineCurveChart
            className="shadow"
            title="Customers"
            // description="No description"
            change={
              data.active_customers_count.length
                ? k_m_formatNumber(
                    data.active_customers_count[0].active_customers
                  ) + ""
                : "0"
            }
            labels={data.new_customers_past_six_months
              .map((d) => d.stats_month)
              .reverse()}
            data={data.new_customers_past_six_months
              .map((d) => d.stats_value as number)
              .reverse()}
            color="success"
            kindOfData="Customers"
            // formatter={(value) => "One " + value + " More"} example on tooltip label
          />
        </div>

        <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-5 mb-xl-10 ">
          <SmoothLineCurveChart
            className="shadow"
            title="Sales"
            // description="No description"
            change={
              data.sales_this_year.length
                ? k_m_formatNumber(
                    parseInt(
                      parseFloat(
                        data.sales_this_year[0].stats_value as string
                      ).toFixed(2)
                    )
                  )
                : "0"
            }
            labels={data.sales_from_invoice_past_six_months
              .map((d) => d.report_month)
              .reverse()}
            data={data.sales_from_invoice_past_six_months
              .map((d) => d.report_value as number)
              .reverse()}
            color="warning"
            kindOfData="Sales"
            formatter={(value) => "TZS " + value}
          />
        </div>

        <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-5 mb-xl-10 ">
          <SmoothLineCurveChart
            className="shadow"
            title="Collections"
            // description="No description"
            change={
              data.collection_this_year.length
                ? k_m_formatNumber(
                    parseInt(
                      parseFloat(
                        data.collection_this_year[0].stats_value as string
                      ).toFixed(2)
                    )
                  )
                : "0"
            }
            labels={data.six_months_collection_trend
              .map((d) => d.report_period)
              .reverse()}
            data={data.six_months_collection_trend
              .map((d) => d.report_value as number)
              .reverse()}
            color="info"
          />
        </div>
      </div>

      {/* New row of tiles */}
      <div className="row px-0 g-4 g-xl-10s mb-5 mb-xl-10  ">
        <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-5 mb-xl-10 ">
          <SmoothLineCurveChart
            className="shadow"
            title="Expenses"
            // description="No description"
            change={
              data.this_year_expenses.length
                ? k_m_formatNumber(
                    parseInt(
                      parseFloat(
                        data.this_year_expenses[0].report_value as string
                      ).toFixed(2)
                    )
                  )
                : "0"
            }
            labels={data.six_months_expense_trend
              .map((d) => d.report_period)
              .reverse()}
            data={data.six_months_expense_trend
              .map((d) => d.report_value as number)
              .reverse()}
            color="danger"
            kindOfData="Expenses"
            // formatter={(value) => "One " + value + " More"} example on tooltip label
          />
        </div>

        <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-5 mb-xl-10 ">
          <SmoothLineCurveChart
            className="shadow"
            title="Bills"
            // description="No description"
            change={
              data.this_year_bills.length
                ? k_m_formatNumber(
                    parseInt(
                      parseFloat(
                        data.this_year_bills[0].report_value as string
                      ).toFixed(2)
                    )
                  )
                : "0"
            }
            labels={data.six_months_bills_trend
              .map((d) => d.report_period)
              .reverse()}
            data={data.six_months_bills_trend
              .map((d) => d.report_value as number)
              .reverse()}
            color="warning"
            kindOfData="Bill"
            formatter={(value) => "TZS " + value}
          />
        </div>

        <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-5 mb-xl-10 ">
          <SmoothLineCurveChart
            className="shadow"
            title="Subscriptions"
            // description="No description"
            change={
              data.this_year_subscription.length
                ? k_m_formatNumber(
                    parseInt(
                      parseFloat(
                        data.this_year_subscription[0].report_count as string
                      ).toFixed(2)
                    )
                  )
                : "0"
            }
            labels={data.six_months_subscription_trend
              .map((d) => d.report_period)
              .reverse()}
            data={data.six_months_subscription_trend
              .map((d) => d.report_value as number)
              .reverse()}
            color="info"
            kindOfData="Subsc"
          />
        </div>
      </div>

      <div className="row g-5 g-xl-10 mb-5 mb-xl-10 ">
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10">
          <ShadowedCard shadow>
            <FaintedTitle title="Latest Requests" className="text-start" />
            <ColoredList
              className="text-start max-w-400px"
              title="Latest Requests"
              list={data.latest_requests}
              intepreter={(item) => {
                let statusLabel = "primary" as
                  | "primary"
                  | "success"
                  | "warning"
                  | "danger";
                if (item.request_status === "Open") {
                  statusLabel = "primary";
                }
                if (item.request_status === "Closed") {
                  statusLabel = "success";
                }
                if (item.request_status === "On Hold") {
                  statusLabel = "warning";
                }
                if (item.request_status === "Cancelled") {
                  statusLabel = "danger";
                }

                return {
                  title: item.request_title,
                  hint: item.request_description + " #" + item.wft_name,
                  status: item.request_status,
                  badgeColor: statusLabel,
                  request_id: item.request_id,
                };
              }}
            />
          </ShadowedCard>
        </div>

        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10">
          <ShadowedCard shadow>
            <FaintedTitle title="Top 10 Products This Year " className="text-start" />
            <PieChartReusable
              data={data.top_ten_products_this_year}
              categoryField="report_category"
              valueField="report_value"
            />
          </ShadowedCard>
        </div>
      </div>
    </>
  );
};

const OverviewWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Overview</PageTitle>
      <Overview />
    </>
  );
};

export { OverviewWrapper, Overview };
