import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../modules/Auth/core/_requests";

export class WorkflowTypesApi {
  private url: string = "workflow/types";
  createWorkFlowType = (data: any) => {
    return httpPost(this.url, data);
  };

  //update WorkFlowType
  updateWorkFlowType = (id: number | string, data: any) => {
    return httpPut(`${this.url}/${id}`, data);
  };

  //Get all WorkFlowType
  pullAllWorkFlowType = (params?: string) => {
    return httpGet(`${this.url}${params ? `?${params}` : ""}`);
  };

  //Get one WorkFlowType
  getOneWorkFlowType = (id: number | string) => {
    return httpGet(`${this.url}/${id}`);
  };

  //Get all WorkFlowType
  deleteWorkFlowType = (id: number | string) => {
    return httpDelete(`${this.url}/${id}`);
  };

  message = (results: any) => {
    if (results.data) {
      return results.data.message;
    } else {
      return "Something went wrong";
    }
  };

  extractTypes = (results: any) => {
    if (results.data && results.data.status) {
      return results.data.data;
    } else {
      return [];
    }
  };
}
