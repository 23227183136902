export type ActionType = {
  type: string;
  payload?: any;
};

export const RequestActions = {
  SET_REQUEST_DATA: "SET_REQUEST_DATA",
  SET_REQUEST_SUBSCRIPTION_DATA: "SET_REQUEST_SUBSCRIPTION_DATA",
  SET_REQUEST_ERROR: "SET_REQUEST_ERROR",
};

export const RequestActionFetching = {
  type: "SET_IS_FETCHING_REQUEST",
  payload: true,
};

export const RequestActionDoneFetching = {
  type: "SET_IS_DONE_FETCHING_REQUEST",
  payload: false,
};

export const setRequestError = (error = "") => ({
  type: RequestActions.SET_REQUEST_ERROR,
  payload: error,
});

export const SET_LOADING_STATUS_WORKFLOW_TYPE =
  "SET_LOADING_STATUS_WORKFLOW_TYPE";

export const setLoadingStatusWorkflowType = (isLoading: boolean) => ({
  type: SET_LOADING_STATUS_WORKFLOW_TYPE,
  payload: isLoading,
});

export const SET_WORKFLOW_TYPE = "SET_WORKFLOW_TYPE";

export const setWorkflowType = (wft: any) => ({
  type: SET_WORKFLOW_TYPE,
  payload: wft,
});
