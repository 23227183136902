import { ShadowedCard } from "../../../general-components/panels/shadowed-card";
import { CrumbHeader } from "../../../general-components/headings/crumb-header";
import { AlignLeft } from "../../../general-components/simple-layouts/align-left";

import { NormalSelect } from "../../../general-components/forms/select-element";

import { NormalInput } from "../../../general-components/forms/input-element";

import { FC, useState } from "react";

import {
  CollapsiblePanel,
  PanelOpenButton,
} from "../../../general-components/panels/collapsible-panel";
import { SearchButton } from "../../../general-components/forms/search-button";
import { PageTitle } from "../../../../_metronic/layout/core";
import {
  DangerBadge,
  MutedBadge,
} from "../../../general-components/elements/badges";
import { HorizontalApart } from "../../../general-components/simple-layouts/horizontal-apart";
import { FlexImmuneButton } from "../../../general-components/forms/buttons";

function MyProjects() {
  const [showPanel, setShowPanel] = useState<Boolean>(false);
  const hideShowPanel = {
    panelName: "SearchPanel",
    iconState: showPanel,
    setIconState: setShowPanel,
  };

  return (
    <>
      <div>
        <ShadowedCard>
          <HorizontalApart>
            <CrumbHeader
              fromPosition="Project Tracker"
              url="#"
              title="Project Tracker"
            />
            <AlignLeft>
              <PanelOpenButton {...hideShowPanel} />
              <FlexImmuneButton title="New Project">
                <i className="fa fa-smile" />
              </FlexImmuneButton>
            </AlignLeft>
          </HorizontalApart>
        </ShadowedCard>

        <CollapsiblePanel {...hideShowPanel}>
          <NormalInput label="Title" />

          <NormalInput label="Description" size="col-sm-3" />

          <NormalSelect
            label="Priority"
            items={["Please select", "Low", "Medium", "High"]}
          />

          <NormalSelect
            label="Private"
            items={["Please select", "Yes", "No"]}
          />

          <NormalSelect
            label="Status"
            items={[
              "Please select",
              "Scheduled",
              "In Progress",
              "Finished",
              "Cancelled",
            ]}
          />

          <NormalSelect label="Created By" items={["Please select", "--"]} />

          <NormalSelect label="Owner" items={["Please select", "--"]} />

          <NormalSelect label="Sort By" items={["Descending", "Ascending"]} />

          <SearchButton />
        </CollapsiblePanel>

        <ShadowedCard shadow>
          {/* Start of table */}
          <div className="table-responsive mt-3">
            <table className="table align-middle gs-0 gy-4">
              <thead>
                <tr className="fw-bold text-muted bg-light px-3">
                  <th className="text-dark fw-bold  ">#</th>

                  <th className="text-dark fw-bold  ">Title</th>

                  <th className="text-dark fw-bold  ">Date Start</th>

                  <th className="text-dark fw-bold  ">Due Date</th>

                  <th className="text-dark fw-bold  ">Priority</th>

                  <th className="text-dark fw-bold  ">Owner</th>

                  <th className="text-dark fw-bold  ">Creator</th>

                  <th className="text-dark fw-bold  ">Label(s)</th>

                  <th className="text-dark fw-bold  ">Status</th>

                  <th className="text-dark fw-bold  ">Action</th>
                </tr>
              </thead>

              <tbody></tbody>
            </table>
          </div>
        </ShadowedCard>
      </div>
    </>
  );
}

const MyProjectsWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>My Projects</PageTitle>

      <MyProjects />
    </>
  );
};

export { MyProjectsWrapper, MyProjects };
