import { Navigate, Route, Routes, Outlet } from "react-router-dom";

import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { SidebarMenuItem } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem";
import { SidebarMenuItemWithSub } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItemWithSub";
import { Suspense, lazy } from "react";
import { SimpleLoader } from "../../general-components/loaders/simple-loader";
const innerRouteBreadCrumbs: Array<PageLink> = [
  {
    title: "Sales Management",
    path: "dashboard",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const SalesManagementPage = () => {
  const SalesDashboard = lazy(() => import("./components/sales-dashboard"));
  const CreateOpportunity = lazy(
    () => import("./components/create-opportunity")
  );
  const OpportunityList = lazy(() => import("./components/opportunity-list"));
  const LeadsList = lazy(() => import("./components/leads-list"));
  const Quotes = lazy(() => import("./components/quotes/quotes"));
  const ServiceOrderForms = lazy(
    () => import("./components/service-order-form/service-order-forms")
  );
  const DailyActivitiesReport = lazy(
    () => import("./components/daily-activities-report")
  );
  const MonthlySalesClosedReport = lazy(
    () => import("./components/monthly-sales-closed-report")
  );
  const MonthlyOpportunityMovementReport = lazy(
    () => import("./components/monthly-opportunity-movement-report")
  );
  const SalesReport = lazy(() => import("./components/sales-report"));
  const NoMovementReport = lazy(
    () => import("./components/no-movement-report")
  );
  const ViewQuote = lazy(() => import("./components/quotes/view-quote"));
  const PrepareSof = lazy(
    () => import("./components/quotes/quotes-prepare-sof")
  );

  const ViewSOF = lazy(() => import("./components/quotes/view-sof"));

  const EditSOF = lazy(
    () => import("./components/quotes/edit-quotes-prepare-sof")
  );

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="dashboard"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Sales Dashboard
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <SalesDashboard />
              </Suspense>
            </>
          }
        />

        <Route
          path="create"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Create Opportunity
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <CreateOpportunity />
              </Suspense>
            </>
          }
        />

        <Route
          path="opportunity-list"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Opportunity List
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <OpportunityList />
              </Suspense>
            </>
          }
        />

        <Route
          path="leads-list"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Leads List
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <LeadsList />
              </Suspense>
            </>
          }
        />

        <Route
          path="quotes"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>Quotes</PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <Quotes />
              </Suspense>
            </>
          }
        />
        <Route
          path="quotes/:id"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>Quotes</PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ViewQuote />
              </Suspense>
            </>
          }
        />

        <Route
          path="quotes/:id/prepare-sof"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>Quotes</PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <PrepareSof />
              </Suspense>
            </>
          }
        />

        <Route
          path="service-order-forms"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Service Order Forms
              </PageTitle>{" "}
              <Suspense fallback={<SimpleLoader />}>
                <ServiceOrderForms />{" "}
              </Suspense>
            </>
          }
        />
        <Route
          path="service-order-forms/:id"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                View SOF
              </PageTitle>{" "}
              <Suspense fallback={<SimpleLoader />}>
                <ViewSOF />{" "}
              </Suspense>
            </>
          }
        />

        <Route
          path="service-order-forms/edit/:id"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Edit SOF
              </PageTitle>{" "}
              <Suspense fallback={<SimpleLoader />}>
                <EditSOF />
              </Suspense>
            </>
          }
        />

        <Route
          path="daily-activities-report"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Daily Activities Report
              </PageTitle>{" "}
              <Suspense fallback={<SimpleLoader />}>
                <DailyActivitiesReport />{" "}
              </Suspense>
            </>
          }
        />

        <Route
          path="monthly-sales-closed-report"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Monthly Sales Closed Report
              </PageTitle>{" "}
              <Suspense fallback={<SimpleLoader />}>
                <MonthlySalesClosedReport />{" "}
              </Suspense>
            </>
          }
        />

        <Route
          path="monthly-opportunity-movement-report"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Monthly Opportunity Movement Report
              </PageTitle>{" "}
              <Suspense fallback={<SimpleLoader />}>
                <MonthlyOpportunityMovementReport />{" "}
              </Suspense>
            </>
          }
        />

        <Route
          path="no-movement-report"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                No Movement Report
              </PageTitle>{" "}
              <Suspense fallback={<SimpleLoader />}>
                <NoMovementReport />{" "}
              </Suspense>
            </>
          }
        />

        <Route
          path="sales-report"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Sales Report
              </PageTitle>{" "}
              <Suspense fallback={<SimpleLoader />}>
                <SalesReport />{" "}
              </Suspense>
            </>
          }
        /> 
        <Route index element={<Navigate to="/sales-management/dashboard" />} />
      </Route>
    </Routes>
  );
};

export default SalesManagementPage;

//============== MODULES ROUTES ==============
export const SalesManagementRoutes = () => (
  <SidebarMenuItemWithSub
    to="/sales-management"
    title="Sales"
    fontIcon="bi-sticky"
    icon="briefcase"
  >
    {/* <SidebarMenuItem
      to="/sales-management/dashboard"
      title="Sales Dashboard"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    {/* <SidebarMenuItem
      to="/sales-management/create"
      title="Create Opportunity"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    {/* <SidebarMenuItem
      to="/sales-management/opportunity-list"
      title="Opportunity List"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    {/* <SidebarMenuItem
      to="/sales-management/leads-list"
      title="Leads List"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    <SidebarMenuItem
      to="/sales-management/quotes"
      title="Quotes"
      icon="tag"
      // fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/sales-management/service-order-forms"
      title="Service Order Forms"
      icon="notepad"
      // fontIcon="bi-person"
    />

    {/* <SidebarMenuItem
      to="/sales-management/daily-activities-report"
      title="Daily Activities Report"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    {/* <SidebarMenuItem
      to="/sales-management/monthly-sales-closed-report"
      title="Monthly Sales Closed Report"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    {/* <SidebarMenuItem
      to="/sales-management/monthly-opportunity-movement-report"
      title="Monthly Opportunity Movement Report"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    {/* <SidebarMenuItem
      to="/sales-management/no-movement-report"
      title="No Movement Report"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}

    {/* <SidebarMenuItem
      to="/sales-management/sales-report"
      title="Sales Report"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}
  </SidebarMenuItemWithSub>
);
