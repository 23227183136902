import { FC } from "react";
import { NavLink } from "react-router-dom";

type Props = {
  fromPosition?: string;
  title?: string;
  url?: string;
  largerTitle?: string;
  hideCrumbs?: boolean;
};
export const CrumbHeader: FC<Props> = ({
  fromPosition,
  title,
  url,
  largerTitle,
  hideCrumbs,
}) => {
  return (
    <div className="text-start">
      <h1 className="fw-lighter text-gray-700">
        {largerTitle || title || "Title"}
      </h1>
      {hideCrumbs ? (
        <></>
      ) : (
        <ol className="breadcrumb text-muted fs-6 fw-bold">
          <li className="breadcrumb-item pe-3">
            <NavLink to={url || "#"} className="pe-3">
              {fromPosition}
            </NavLink>
          </li>
          <li className="breadcrumb-item px-3 text-muted">
            {title || "Title"}
          </li>
        </ol>
      )}
    </div>
  );
};
