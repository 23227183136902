import { ShadowedCard } from "../../../general-components/panels/shadowed-card";
import { CrumbHeader } from "../../../general-components/headings/crumb-header";
import { AlignLeft } from "../../../general-components/simple-layouts/align-left";

import { NormalSelect } from "../../../general-components/forms/select-element";

import { NormalInput } from "../../../general-components/forms/input-element";

import { FC, useState } from "react";

import {
  CollapsiblePanel,
  PanelOpenButton,
} from "../../../general-components/panels/collapsible-panel";
import { SearchButton } from "../../../general-components/forms/search-button";
import { PageTitle } from "../../../../_metronic/layout/core";
import {
  SuccessBadge,
  WarningBadge,
} from "../../../general-components/elements/badges";
import { HorizontalApart } from "../../../general-components/simple-layouts/horizontal-apart";
import { FlexImmuneButton } from "../../../general-components/forms/buttons";

function MeetingsManager() {
  const [showPanel, setShowPanel] = useState<Boolean>(false);
  const hideShowPanel = {
    panelName: "SearchPanel",
    iconState: showPanel,
    setIconState: setShowPanel,
  };

  return (
    <>
      <div>
        <ShadowedCard>
          <HorizontalApart>
            <CrumbHeader
              fromPosition="Minutes Tracker"
              url="#"
              title="Meeting Manager"
            />
            <AlignLeft>
              <PanelOpenButton {...hideShowPanel} />
              <FlexImmuneButton title="Create Meeting">
                <i className="fa fa-smile" />
              </FlexImmuneButton>
            </AlignLeft>
          </HorizontalApart>
        </ShadowedCard>

        <CollapsiblePanel {...hideShowPanel}>
          <NormalInput label="Title" />

          <NormalSelect
            label="Type"
            items={["Please select", "Adhoc", "Project", "Recurrent"]}
          />

          <NormalSelect
            label="Status"
            items={[
              "Please select",
              "Scheduled",
              "In Progress",
              "Finished",
              "Cancelled",
            ]}
          />

          <SearchButton />
        </CollapsiblePanel>

        <ShadowedCard shadow>
          {/* Start of table */}
          <div className="table-responsive mt-3">
            <table className="table align-middle gs-0 gy-4">
              <thead>
                <tr className="fw-bold text-muted bg-light px-3">
                  <th className="text-dark fw-bold  ">#</th>

                  <th className="text-dark fw-bold min-w-100px ">
                    Created Date
                  </th>

                  <th className="text-dark fw-bold  ">Type</th>

                  <th className="text-dark fw-bold  ">Title</th>

                  <th className="text-dark fw-bold min-w-100px ">
                    Meeting Date
                  </th>

                  <th className="text-dark fw-bold  ">Venue</th>

                  <th className="text-dark fw-bold  ">Status</th>

                  <th className="text-dark fw-bold  ">Action</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1</td>

                  <td>06 Oct 2022</td>

                  <td>
                    <SuccessBadge className="badge">Project</SuccessBadge>
                  </td>

                  <td>Test</td>

                  <td>03 Oct 2022</td>

                  <td>RM 1</td>

                  <td>
                    <WarningBadge className="badge">Active</WarningBadge>
                  </td>

                  <td>
                    <i className="fa fa-eye text-info" />
                  </td>
                </tr>

                <tr>
                  <td>1</td>

                  <td>06 Oct 2022</td>

                  <td>
                    <SuccessBadge className="badge">Project</SuccessBadge>
                  </td>

                  <td>Test</td>

                  <td>03 Oct 2022</td>

                  <td>RM 1</td>

                  <td>
                    <WarningBadge className="badge">Active</WarningBadge>
                  </td>

                  <td>
                    <i className="fa fa-eye text-info" />
                  </td>
                </tr>

                <tr>
                  <td>1</td>

                  <td>06 Oct 2022</td>

                  <td>
                    <SuccessBadge className="badge">Project</SuccessBadge>
                  </td>

                  <td>Test</td>

                  <td>03 Oct 2022</td>

                  <td>RM 1</td>

                  <td>
                    <WarningBadge className="badge">Active</WarningBadge>
                  </td>

                  <td>
                    <i className="fa fa-eye text-info" />
                  </td>
                </tr>

                <tr>
                  <td>1</td>

                  <td>06 Oct 2022</td>

                  <td>
                    <SuccessBadge className="badge">Project</SuccessBadge>
                  </td>

                  <td>Test</td>

                  <td>03 Oct 2022</td>

                  <td>RM 1</td>

                  <td>
                    <WarningBadge className="badge">Active</WarningBadge>
                  </td>

                  <td>
                    <i className="fa fa-eye text-info" />
                  </td>
                </tr>

                <tr>
                  <td>1</td>

                  <td>06 Oct 2022</td>

                  <td>
                    <SuccessBadge className="badge">Project</SuccessBadge>
                  </td>

                  <td>Test</td>

                  <td>03 Oct 2022</td>

                  <td>RM 1</td>

                  <td>
                    <WarningBadge className="badge">Active</WarningBadge>
                  </td>

                  <td>
                    <i className="fa fa-eye text-info" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ShadowedCard>
      </div>
    </>
  );
}

const MeetingsManagerWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Meetings Manager</PageTitle>

      <MeetingsManager />
    </>
  );
};

export { MeetingsManagerWrapper, MeetingsManager };
