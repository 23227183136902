// src/components/PieChart.tsx
import { useLayoutEffect, FC } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const PieChartReusable: FC<{
  valueField: string; //valueField : 'value'
  categoryField: string; //categoryField : 'category'
  data: any[]; //just the array of data list
}> = ({ valueField, categoryField, data }) => {
  useLayoutEffect(() => {
    // Create root element
    const root = am5.Root.new("chartdiv");

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        // radius: am5.percent(50)
      })
    );

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField,
        categoryField,
      })
    );

    series.data.setAll(data);

    series.labels.template.set("forceHidden", true);

    // Make chart animate on load
    series.appear(1000, 100);
    
    // Create legend
    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      layout: root.verticalLayout
    }));
    
    legend.data.setAll(series.dataItems);

    return () => {
      root.dispose(); // Cleanup chart instance on component unmount
    };
  }, []);

  return <div id="chartdiv" style={{ width: "100%", height: "500px" }} />;
};

export default PieChartReusable;
