import { lazy, FC, Suspense, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
// import {MenuTestPage} from '../pages/MenuTestPage'
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { OverviewWrapper } from "../modules/Dashboard/components/Overview";

const PrivateRoutes = () => {
  const DashboardPage = lazy(
    () => import("../modules/Dashboard/dashboardPage")
  );
  const WorkflowPage = lazy(() => import("../modules/workflow/workflowPage"));

  const ContactPage = lazy(() => import("../modules/Contacts/ContactPage"));
  const SubscriptionPage = lazy(
    () => import("../modules/Subscriptions/subscriptionPage")
  );
  const SalesManagementPage = lazy(
    () => import("../modules/SalesManagement/SalesManagementPage")
  );
  const OrderFulFillmentPage = lazy(
    () => import("../modules/OrderFulfillment/OrderFulFillmentPage")
  );
  const FinancePage = lazy(() => import("../modules/Finance/FinancePage"));
  // const ProjectTrackerPage = lazy(
  //   () => import("../modules/ProjectTracker/ProjectTrackerPage")
  // );
  // const CashbookPage = lazy(() => import("../modules/CashBook/CashbookPage"));
  const UnitsAndProvidersPage = lazy(
    () => import("../modules/UnitsProviders/UnitsAndProvidersPage")
  );
  const InventoryPage = lazy(
    () => import("../modules/Inventory/InventoryPage")
  );
  // const IntegrationPage = lazy(
  //   () => import("../modules/Integration/IntegrationPage")
  // );
  const ReportsPage = lazy(() => import("../modules/Reports/ReportsPage"));

  const SettingsPage = lazy(() => import("../modules/Settings/SettingsPage"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        {/* <Route path='/subscriptions' element={<Subscriptions />} /> */}
        <Route
          path="trackingportal"
          element={<Navigate to="/crafted/pages/tracking/portal" />}
        />

        <Route path="analytics" element={<Navigate to="/error/500" />} />
        <Route path="tools" element={<Navigate to="/error/404" />} />
        {/* <Route path="dashboard" element={<DashboardWrapper />} /> */}
        <Route path="dashboard/overview" element={<OverviewWrapper />} />

        {/* <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} /> */}

        {/* Lazy Modules */}

        <Route
          path="dashboard/*"
          element={
            <SuspensedView>
              <DashboardPage />
            </SuspensedView>
          }
        />
        <Route
          path="crm/*"
          element={
            <SuspensedView>
              <ContactPage />
            </SuspensedView>
          }
        />
        <Route
          path="billing/*"
          element={
            <SuspensedView>
              <SubscriptionPage />
            </SuspensedView>
          }
        />
        <Route
          path="sales-management/*"
          element={
            <SuspensedView>
              <SalesManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path="order-fulfillment/*"
          element={
            <SuspensedView>
              <OrderFulFillmentPage />
            </SuspensedView>
          }
        />
        <Route
          path="finance/*"
          element={
            <SuspensedView>
              <FinancePage />
            </SuspensedView>
          }
        />
        <Route
          path="units-and-providers/*"
          element={
            <SuspensedView>
              <UnitsAndProvidersPage />
            </SuspensedView>
          }
        />
        <Route
          path="inventory/*"
          element={
            <SuspensedView>
              <InventoryPage />
            </SuspensedView>
          }
        />

        <Route
          path="workflow/*"
          element={
            <SuspensedView>
              <WorkflowPage />
            </SuspensedView>
          }
        />
        <Route
          path="reports/*"
          element={
            <SuspensedView>
              <ReportsPage />
            </SuspensedView>
          }
        />
        <Route
          path="settings/*"
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  // console.log("Intenedd");
  // localStorage.setItem("intendedPath", window.location.pathname);
  // console.log(window.location.pathname);
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
