import { FC, useEffect, useRef } from "react";
import { ShadowedCard } from "./panels/shadowed-card";
import { MIS_SUCCESS, MIS_SUCCESS_LIGHT } from "../../_metronic/mis.themes";
import "./tabs.css";
import { CrumbHeader } from "./headings/crumb-header";
import { textToSlug } from "../utils/text-utils";
import { useLocation, useNavigate } from "react-router-dom";
interface Props {
  contents: any[];
  id?: any;
  title?: string;
  module?: string;
}

export const Tabs: FC<Props> = ({ contents, id, title, module }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);
  // const [count, setCount] = useState<number | string>(0);
  const scrollLeft = () => {
    if (scrollRef.current) {
      // Adjust the scroll position as needed
      scrollRef.current.style.transition = "scroll-left 0.5s ease-in-out"; // Add a transition effect
      scrollRef.current.scrollLeft += 50; // You can change the value based on your requirements
    }
  };
  const scrollRight = () => {
    if (scrollRef.current) {
      // Adjust the scroll position as needed
      scrollRef.current.style.transition = "scroll-right 0.5s ease-in-out"; // Add a transition effect
      scrollRef.current.scrollLeft -= 50; // You can change the value based on your requirements
    }
  };
  useEffect(() => {
    const hasReloaded = sessionStorage.getItem("hasReloaded");
    const pattern = /\/crm\/organizations\/(\d+|\w+)/; //Dont do crazy refresh on organizations page
    if (!hasReloaded && !pattern.test(location.pathname)) {
      // Reload the page and set a flag in sessionStorage to prevent further reloads
      sessionStorage.setItem("hasReloaded", "true");
      window.location.reload(); // Refresh the page
      // navigate(0);
    }

    // Clear the flag when navigating to a new page (if needed)
    return () => {
      sessionStorage.removeItem("hasReloaded");
    };
  }, [location]);
  return (
    <div className="mt-3">
      {title && module ? (
        <CrumbHeader fromPosition={module} url="#" title={title} />
      ) : null}
      <nav className="mt-3">
        <div
          className="nav nav-tabs d-flex flex-nowrap"
          id="nav-tab"
          role="tablist"
        >
          <span
            onClick={scrollRight}
            className="btn btn-icon btn-sm rounded me-2 px-1"
            style={{ background: MIS_SUCCESS_LIGHT, color: MIS_SUCCESS }}
          >
            <span className="fa fa-chevron-left"></span>
          </span>
          <div
            className="d-flex flex-nowrap overflow-auto overflow-hidden scroll-container"
            ref={scrollRef}
          >
            {contents.map((it: any, idx: number) => {
              return (
                <button
                  className={`nav-link first text-nowrap align-self-center ${
                    idx ? "" : "active"
                  } ${idx === 0 ? "ms-3" : ""}`}
                  id={`nav-${it.hook}-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#nav-${it.hook}-${textToSlug(module)}`}
                  type="button"
                  role="tab"
                  aria-controls={`nav-${it.hook}`}
                  aria-selected={idx ? false : true}
                >
                  {it.name}
                </button>
              );
            })}
          </div>
          <span
            onClick={scrollLeft}
            className="btn btn-icon btn-sm rounded me-2 px-1"
            style={{ background: MIS_SUCCESS_LIGHT, color: MIS_SUCCESS }}
          >
            <span className="fa fa-chevron-right"></span>
          </span>
        </div>
      </nav>

      <div className="tab-content mb-0" id="nav-tabContent">
        {contents.map((it: any, idx: number) => {
          const { component: Component } = it;
          return (
            <div
              className={`tab-pane fade show ${idx ? "" : "active"}`}
              id={`nav-${it.hook}-${textToSlug(module)}`}
              role="tabpanel"
              aria-labelledby={`nav-${it.hook}-tab`}
              tabIndex={0}
            >
              <ShadowedCard shadow topMargin="my-0">
                <Component id={id} />
              </ShadowedCard>
            </div>
          );
        })}
      </div>
    </div>
  );
};
