import { createContext, FC, ReactNode, useEffect, useReducer } from "react";
import { RequestReducer } from "./reducer";
import {
  initialState,
  RequestViewInitialStateType,
} from "./request-view-initialstate";
import { useGetWorkflowType } from "../../../Settings/components/workflows/types/use-types";
import { useParams } from "react-router-dom";
import { setLoadingStatusWorkflowType, setWorkflowType } from "./actions";

export const RequestViewContext = createContext({} as any);

interface ChildrenProps {
  children: ReactNode;
}

export const RequestViewProvider: FC<ChildrenProps> = ({ children }) => {
  const [state, dispatch] = useReducer(RequestReducer, initialState);

  //TODO WE'LL UN COMMENT THIS ON SCREEN ITSELF WHEN WE HAVE WFT_ID
  // const {
  //   wft, // WorkFlowTypeType
  //   isFetchingTypes,
  // } = useGetWorkflowType(id as string);

  // useEffect(() => {
  //   dispatch(setLoadingStatusWorkflowType(isFetchingTypes));
  //   dispatch(setWorkflowType(wft));
  // }, [id, isFetchingTypes]);

  // console.log(state.workflowType);
  //i think this provider needs to fetch workflow type as well
  return (
    <RequestViewContext.Provider value={{ state, dispatch }}>
      {children}
    </RequestViewContext.Provider>
  );
};
