type SelectPropType = {
  label: string;
  size?: string;
  marginTop?: string;
  value?: string;
  onChange?: (e: any) => void;
};
export const NormalDate = ({
  label,
  size,
  marginTop,
  onChange,
  value,
}: SelectPropType) => {
  return (
    <div className={`${size || "col-sm-2"} ${marginTop || "mt-2"}`}>
      <span className="fs-7 text-dark fw-bold ms-1">{label}: </span>
      <input
        defaultValue={value}
        className="form-control form-control-sm"
        type="date"
        id=""
        onChange={onChange}
      />
    </div>
  );
};
