import axios, { AxiosError } from "axios";
import { AuthModel, UserModel } from "./_models";
import {
  BASE_URL,
  LAST_VISITED,
  LOGIN_PAGE,
} from "../../Profile/components/constants";
import { CurrencyApi } from "../../../apis/currencyApi";
import { toast } from "react-toastify";

const api = new CurrencyApi();

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${BASE_URL}auth/verify-token`;
export const LOGIN_URL = `${BASE_URL}auth/login`;
export const AUTH_VERIFY_2FA = `${BASE_URL}auth/verify`;
// export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token` /
// export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${BASE_URL}auth/forgot-password`;
export const REQUEST_RESET_PASSWORD_URL = `${BASE_URL}auth/reset-password`;

// export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  });
}

// verification

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function resetPassword(
  email: string,
  password?: string,
  confirm_password?: string,
  token?: string
) {
  return axios.post<{ result: boolean }>(REQUEST_RESET_PASSWORD_URL, {
    email,
    password,
    token,
    password_confirmation: confirm_password,
  });
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  });
}

export function getCurrencies() {
  return api.pullAllCurrency();
}

axios.interceptors.request.use(
  (config: any) => {
    // You can modify the request config here
    // config.headers["x-organization-id"] = `1`;
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // Return a successful response
    return response;
  },
  (error) => {
    const status = error?.response?.status;
    const lastVisitedPage = window.location.pathname;
    if (!lastVisitedPage.includes("login")) {
      if (status === 401) {
        console.warn("Unauthorized access - redirect to login");
        window.location.href = LOGIN_PAGE;
        return Promise.reject("Unauthorized. Please log in again.");
      }
      if (status === 403) {
        console.warn("Access denied - you do not have permission.");
        toast("Access denied. You do not have permission.", { type: "error" });
        return Promise.reject("Access denied. You do not have permission.");
      }
      if (status === 400 || status === 500) {
        let message = error.response.data.message || "An error occurred";
        toast(message, { type: "error" });
        return Promise.reject(message);
      }
      if (!status) {
        toast("Server error has occured", { type: "error" });
        return Promise.reject("Server error has occured");
      }
      if (error.response?.status === 419) {
        // Handle 419 error (session expired)
        // For example, redirect to the login page
        window.location.href = "/loginx";
      }
    }

    // If it's not a 419 OR 401 error, pass the error along
    return Promise.reject(error);
  }
);
//getting record
export const httpGet = (path: string, headers?: any) => {
  if (!headers) {
    return axios.get(`${BASE_URL}${path}`);
  }
  return axios.get(`${BASE_URL}${path}`, headers);
};

export const httpPostWithFile = (path: any, data: any) => {
  let formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  return axios.post(`${BASE_URL}${path}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const httpPost = (path: any, data: any, withFile?: boolean) => {
  //check if we use file parameter or not

  if (withFile) {
    return axios.post(`${BASE_URL}${path}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  return axios.post(`${BASE_URL}${path}`, data);
};

export const httpDelete = (path: any) => {
  return axios.delete(`${BASE_URL}${path}`);
};

export const httpPut = (path: any, data: any, withFile?: boolean) => {
  if (withFile) {
    return axios.put(`${BASE_URL}${path}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  return axios.put(`${BASE_URL}${path}`, data);
};
