import { FC, ReactElement } from "react";

type PropType = {
  topMargin?: string;
  children?: ReactElement | ReactElement[] | null;
  shadow?: Boolean;
  textAlign?: "left" | "right" | "center" | "justify" | "initial" | "inherit";
};
export const ShadowedCard: FC<PropType> = ({
  topMargin,
  children,
  shadow,
  textAlign,
}) => {
  //TODO margin
  return (
    <div
      className={`no-print ${shadow ? "card" : "text-start"} ${
        topMargin ? topMargin : "my-5"
      } ${shadow ? "shadow" : ""} `}
    >
      <div
        className={`${shadow ? "card-body" : ""}`}
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: textAlign ? textAlign : "center",
        }}
      >
        {children}
      </div>
    </div>
  );
};
