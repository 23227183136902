import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import SidebarMenuItemWithSub from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItemWithSub";
import { SidebarMenuItem } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem";
import { lazy, Suspense } from "react";
import { SimpleLoader } from "../../general-components/loaders/simple-loader";
import { useGetWorkflowTypes } from "../Settings/components/workflows/types/use-types";
import { WorkFlowTypeType } from "../Settings/components/workflows/types/types.type";
import { RequestViewProvider } from "./Requests/request-view-data-engine/context";

const wizardsBreadCrumbs: Array<PageLink> = [
  {
    title: "Wizards",
    path: "/requests/index",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const WorkflowPage = () => {
  const RequestPage = lazy(() => import("./Requests/requests.index"));
  const WorkflowRequestView = lazy(() => import("./Requests/view.request"));
  const ScheduleList = lazy(() => import("./schedules/schedule-list"));

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="requests/:id"
          element={
            <>
              <PageTitle breadcrumbs={wizardsBreadCrumbs}>Horizontal</PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <RequestPage />
              </Suspense>
            </>
          }
        />

        <Route
          path="schedules"
          element={
            <>
              <PageTitle breadcrumbs={wizardsBreadCrumbs}>Horizontal</PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ScheduleList />
              </Suspense>
            </>
          }
        />
        <Route
          path="request/:id"
          element={
            <>
              <PageTitle breadcrumbs={wizardsBreadCrumbs}>Vertical</PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <RequestViewProvider>
                  <WorkflowRequestView />
                </RequestViewProvider>
              </Suspense>
            </>
          }
        />
        <Route index element={<Navigate to="/workflow/requests" />} />
      </Route>
    </Routes>
  );
};

export default WorkflowPage;

export const WorkflowRoutes = () => {
  const { isFetchingTypes, workflowTypes } = useGetWorkflowTypes();

  //
  return (
    <SidebarMenuItemWithSub
      to="/workflow"
      title="Workflow"
      fontIcon="bi-person"
      icon="abstract-4"
    >
      {isFetchingTypes ? (
        <span className="text-success">Loading...</span>
      ) : (
        <>
          {workflowTypes.map((data: WorkFlowTypeType, index: number) => (
            <SidebarMenuItem
              key={data.wft_id}
              to={`/workflow/requests/${data.wft_id}`}
              title={data.wft_name as string}
              icon="watch"
              fontIcon="bi-person"
            />
          ))}
        </>
      )}

      <SidebarMenuItem
        to="/workflow/schedules"
        title="Schedules"
        icon="some-files"
        fontIcon="bi-person"
      />
      {/* <SidebarMenuItem
          to="/billing/adjustments"
          title="Adjustments"
          icon="update-file"
          fontIcon="bi-person"
        />
    
        <SidebarMenuItem
          to="/billing/statement"
          title="Statement"
          icon="document"
          fontIcon="bi-person"
        /> */}
      {/* <SidebarMenuItem
          to="/subscriptions/report"
          title="Report"
          icon="scroll"
          fontIcon="bi-person"
        /> */}
    </SidebarMenuItemWithSub>
  );
};
