import { useIntl } from "react-intl";
import { MenuItem } from "./MenuItem";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { MegaMenu } from "./MegaMenu";

export function MenuInner() {
  const intl = useIntl();
  return <div></div>;
  return (
    <>
      <MenuItem
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        to="/dashboard"
      />
      {/* <MenuItem title="Tracking Portal" to="/crafted/pages/tracking/portal" />
      <MenuItem title="BI Analytics" to="/crafted/pages/bi/analytics" /> */}

      <MenuInnerWithSub
        title="Switch Company"
        to="/crafted/pages/tools/"
        menuPlacement="bottom-start"
        menuTrigger="hover"
        hasArrow={true}
        isMega={true}
      >
        {/* PAGES */}

        <MenuItem
          // hasBullet={true}
          to="/crafted/pages/tools/geofence/monitoring"
          title="Laiki Technology Ltd"
          textColor="black"
          bulletColor="black"
          // icon="map"
        />
      </MenuInnerWithSub>

      {/* <MenuItem title='Layout Builder' to='/builder' /> */}
      {/* <MenuItem title='Subscriptions' to='/subscriptions' /> */}
    </>
  );
}
