import clsx from "clsx";
import { useLayout } from "../../core";
import { Footer } from "./Footer";
import { MIS_DARK } from "../../../mis.themes";

const FooterWrapper = () => {
  const { config } = useLayout();
  if (!config.app?.footer?.display) {
    return null;
  }

  return (
    <div
      className="app-footer"
      id="kt_app_footer"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: MIS_DARK,
      }}
    >
      {config.app.footer.containerClass ? (
        <div
          className={clsx("app-container", config.app.footer.containerClass)}
        >
          <Footer />
        </div>
      ) : (
        <Footer />
      )}
    </div>
  );
};

export { FooterWrapper };
