//================== TaxRates END POINTS =============

import {
  httpPost,
  httpPut,
  httpGet,
  httpDelete,
} from "../modules/Auth/core/_requests";

export class TaxRatesApi {
  //creating TaxRates
  private url: string = "settings/tax-rates";
  createTaxRates = (data: any) => {
    return httpPost(this.url, data);
  };

  //update TaxRates
  updateTaxRates = (id: number | string, data: any) => {
    return httpPut(`${this.url}/${id}`, data);
  };

  //Get all TaxRates
  pullAllTaxRates = () => {
    return httpGet(this.url);
  };

  //Get one TaxRates
  getOneTaxRates = (id: number | string) => {
    return httpGet(`${this.url}/${id}`);
  };

  //Get all TaxRates
  deleteTaxRates = (id: number | string) => {
    return httpDelete(`${this.url}/${id}`);
  };

  //extract rates from backend results
  extractRates = (results: any) => {
    if (results.data && results.data.status) {
      return results.data.data;
    }
    return undefined;
  };
}
