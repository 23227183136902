import React, { FC } from "react";
import styled, { keyframes } from "styled-components";
export const SimpleLoader = () => <div>Loading...</div>;
export const TableLoader = () => <h2>Loading...</h2>;
// LoadingBars.tsx

const shimmer = keyframes`
  0% {
    background-position: -150% 0;
  }
  100% {
    background-position: 150% 0;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const LoadingBar = styled.div`
  width: 100%;
  height: 16px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite linear;
  border-radius: 4px;
`;

const LoadingBars: React.FC = () => {
  return (
    <LoadingContainer>
      <LoadingBar />
      <LoadingBar />
      <LoadingBar />
    </LoadingContainer>
  );
};

export const LoadingSingleBar: React.FC = () => {
  return (
    <LoadingContainer>
      <LoadingBar />
    </LoadingContainer>
  );
};

export default LoadingBars;

const pulse = keyframes`
  0%, 40%, 100% {
    transform: scaleY(1);
  }
  20% {
    transform: scaleY(1.5);
  }
`;

const LoadingContainer2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
`;

const Bar = styled.div`
  width: 4px;
  height: 24px;
  margin: 0 2px;
  background-color: #007bff;
  animation: ${pulse} 1.2s infinite ease-in-out;

  &:nth-child(1) {
    animation-delay: -1.2s;
  }
  &:nth-child(2) {
    animation-delay: -1.1s;
  }
  &:nth-child(3) {
    animation-delay: -1s;
  }
  &:nth-child(4) {
    animation-delay: -0.9s;
  }
  &:nth-child(5) {
    animation-delay: -0.8s;
  }
`;

export const LoadingVerticalBars: React.FC = () => {
  return (
    <LoadingContainer2>
      <Bar />
      <Bar />
      <Bar />
      <Bar />
      <Bar />
    </LoadingContainer2>
  );
};

export const TableStrippesLoader: FC<{ colspan: number }> = ({ colspan }) => {
  return (
    <tr>
      <td colSpan={colspan}>
        <LoadingBars />
      </td>
    </tr>
  );
};

export const LoadingFormComponent: FC<{ size: string }> = ({ size }) => {
  return (
    <div className={`${size} pt-5`} style={{ marginTop: 12 + "px" }}>
      <LoadingSingleBar />
    </div>
  );
};
