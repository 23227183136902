type Props = {
  color?: string;
  label?: string;
  loading?: Boolean | boolean;
};
export const SpinnerSm = ({ color, label, loading }: Props) => {
  return (
    <>
      {loading ? <span>wait...</span> : <span>{label}</span>}
      {loading ? (
        <div
          className={`spinner-border spinner-border-sm ${
            color ? "text-" + color : "text-light"
          }`}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : null}
    </>
  );
};

export const EmptySpinner = ({ color }: Props) => {
  return (
    <div
      className={`spinner-border spinner-border-sm ${
        color ? "text-" + color : "text-light"
      }`}
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};
