//
export function debounce<T extends (...args: any[]) => Promise<any>>(
  func: T,
  delay: number
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: Parameters<T>): Promise<ReturnType<T>> {
    const context = this;
    clearTimeout(timeoutId);
    return new Promise((resolve) => {
      timeoutId = setTimeout(async () => {
        const result = await func.apply(context, args);
        resolve(result);
      }, delay);
    });
  };
}

//debouce time
export const DEBOUNCE_TIME = 1000;
