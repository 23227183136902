import { ShadowedCard } from "../../../general-components/panels/shadowed-card";
import { CrumbHeader } from "../../../general-components/headings/crumb-header";
import { AlignLeft } from "../../../general-components/simple-layouts/align-left";

import { NormalSelect } from "../../../general-components/forms/select-element";

import { NormalInput } from "../../../general-components/forms/input-element";

import { FC, useState } from "react";

import {
  CollapsiblePanel,
  PanelOpenButton,
} from "../../../general-components/panels/collapsible-panel";
import { SearchButton } from "../../../general-components/forms/search-button";
import { PageTitle } from "../../../../_metronic/layout/core";
import { DangerBadge } from "../../../general-components/elements/badges";
import { HorizontalApart } from "../../../general-components/simple-layouts/horizontal-apart";
import { FlexImmuneButton } from "../../../general-components/forms/buttons";

function MyTasks() {
  const [showPanel, setShowPanel] = useState<Boolean>(false);
  const hideShowPanel = {
    panelName: "SearchPanel",
    iconState: showPanel,
    setIconState: setShowPanel,
  };

  return (
    <>
      <div>
        <ShadowedCard>
          <HorizontalApart>
            <CrumbHeader
              fromPosition="Action Tracker"
              url="#"
              title="My Tasks"
            />
            <AlignLeft>
              <PanelOpenButton {...hideShowPanel} />
              <FlexImmuneButton title="New task">
                <i className="fa fa-smile" />
              </FlexImmuneButton>
            </AlignLeft>
          </HorizontalApart>
        </ShadowedCard>

        <CollapsiblePanel {...hideShowPanel}>
          <NormalInput label="Title" />

          <NormalInput label="Description" size="col-sm-3" />

          <NormalSelect
            label="Type"
            items={["Please select", "Task", "KPI", "KRI"]}
          />

          <NormalSelect
            label="Status"
            items={["Please select", "To Do", "In Progress", "Done"]}
          />

          <NormalSelect label="Created by" items={["Type to search", "--"]} />

          <NormalSelect
            label="Assigned User"
            size="col-sm-3"
            items={["Type to search", "--"]}
          />

          <NormalSelect
            label="Sort By"
            items={["Default", "Due Date", "Action Date", "Completed Date"]}
          />

          <NormalSelect
            label="Sort Order"
            items={["Ascending", "Descending"]}
          />

          <NormalSelect
            label="Show Sub Tasks"
            size="col-sm-3"
            items={["Any", "Yes", "No"]}
          />

          <SearchButton />
        </CollapsiblePanel>

        <ShadowedCard shadow>
          {/* Start of table */}
          <div className="table-responsive mt-3">
            <table className="table align-middle gs-0 gy-4">
              <thead>
                <tr className="fw-bold text-muted bg-light px-3">
                  <th className="text-dark fw-bold  ">#</th>

                  <th className="text-dark fw-bold  ">Type</th>

                  <th className="text-dark fw-bold  ">Action Date</th>

                  <th className="text-dark fw-bold  ">Action</th>

                  <th className="text-dark fw-bold  ">Sub Actions</th>

                  <th className="text-dark fw-bold  ">Due Date</th>

                  <th className="text-dark fw-bold min-w-100px ">
                    Completed Date
                  </th>

                  <th className="text-dark fw-bold  ">Status</th>

                  <th className="text-dark fw-bold  ">Assigned</th>

                  <th className="text-dark fw-bold  ">Creator</th>

                  <th className="text-dark fw-bold  ">View</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>75</td>

                  <td>Task</td>

                  <td>25 Feb 2022</td>

                  <td>Another test</td>

                  <td>0</td>

                  <td>09 Sep 2022</td>

                  <td></td>

                  <td>
                    <DangerBadge className="badge">To Do</DangerBadge>
                  </td>

                  <td>Eliud Mwanuzi</td>

                  <td>Levy Mwijarubi</td>

                  <td>
                    <i className="fa fa-eye text-info" />
                  </td>
                </tr>

                <tr>
                  <td>75</td>

                  <td>Task</td>

                  <td>25 Feb 2022</td>

                  <td>Another test</td>

                  <td>0</td>

                  <td>09 Sep 2022</td>

                  <td></td>

                  <td>
                    <DangerBadge className="badge">To Do</DangerBadge>
                  </td>

                  <td>Eliud Mwanuzi</td>

                  <td>Levy Mwijarubi</td>

                  <td>
                    <i className="fa fa-eye text-info" />
                  </td>
                </tr>

                <tr>
                  <td>75</td>

                  <td>Task</td>

                  <td>25 Feb 2022</td>

                  <td>Another test</td>

                  <td>0</td>

                  <td>09 Sep 2022</td>

                  <td></td>

                  <td>
                    <DangerBadge className="badge">To Do</DangerBadge>
                  </td>

                  <td>Eliud Mwanuzi</td>

                  <td>Levy Mwijarubi</td>

                  <td>
                    <i className="fa fa-eye text-info" />
                  </td>
                </tr>

                <tr>
                  <td>75</td>

                  <td>Task</td>

                  <td>25 Feb 2022</td>

                  <td>Another test</td>

                  <td>0</td>

                  <td>09 Sep 2022</td>

                  <td></td>

                  <td>
                    <DangerBadge className="badge">To Do</DangerBadge>
                  </td>

                  <td>Eliud Mwanuzi</td>

                  <td>Levy Mwijarubi</td>

                  <td>
                    <i className="fa fa-eye text-info" />
                  </td>
                </tr>

                <tr>
                  <td>75</td>

                  <td>Task</td>

                  <td>25 Feb 2022</td>

                  <td>Another test</td>

                  <td>0</td>

                  <td>09 Sep 2022</td>

                  <td></td>

                  <td>
                    <DangerBadge className="badge">To Do</DangerBadge>
                  </td>

                  <td>Eliud Mwanuzi</td>

                  <td>Levy Mwijarubi</td>

                  <td>
                    <i className="fa fa-eye text-info" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ShadowedCard>
      </div>
    </>
  );
}

const MyTasksWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>My Tasks</PageTitle>

      <MyTasks />
    </>
  );
};

export { MyTasksWrapper, MyTasks };
