import { FC } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

const QboAuthenticate: FC = () => {
  const urlParams = new URL(window.location.href).searchParams;
  const code = urlParams.get("code"); // Replace 'param1' with the parameter name
  const realmId = urlParams.get("realmId");
  window.opener.postMessage(
    {
      code,
      realmId,
    },
    "*"
  );
  return (
    <>
      {/* begin::Title */}
      <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">Please wait</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className="fw-semibold fs-6 text-gray-500 mb-7">
        This page will automatically close in a few seconds.
      </div>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className="mb-3">
        <img
          src={toAbsoluteUrl("/media/auth/trackmis-404.png")}
          className="mw-100 mh-300px theme-light-show"
          alt=""
        />
        <img
          src={toAbsoluteUrl("/media/auth/trackmis-404.png")}
          className="mw-100 mh-300px theme-dark-show"
          alt=""
        />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className="mb-0">
        <Link to="/dashboard" className="btn btn-sm btn-danger">
          Return to Dashboard
        </Link>
      </div>
      {/* end::Link */}
    </>
  );
};

export { QboAuthenticate };
