/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { PageTitle } from "../../../../_metronic/layout/core";
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  CardsWidget21,
  ChartsWidget1,
  ListsWidget26,
  EngageWidget10,
} from "../../../../_metronic/partials/widgets";
import axios from "axios";

const DashboardPage: FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    orders: 0,
    units: 0,
    invoice: 0,
    outstanding: 0,
    trouble_tickets: 0,
    training_tickets: 0,
    service_tickets: 0,
  });

  const loadStats = async () => {
    try {
      setIsLoading(true);
      let results = await axios.get("http://localhost:8000/api/stats");
      if (results.data.success) {
        setData({
          ...results.data,
        });
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  console.log(data);
  useEffect(() => {
    loadStats();
  }, []);
  return (
    <>
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        {/* begin::Col */}
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10 ">
          <CardsWidget21
            className="h-md-100 mb-5 mb-xl-10"
            description="Pending Orders"
            color="#BC444D"
            total={data.orders}
            img={toAbsoluteUrl("")}
          />
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10 ">
          <CardsWidget21
            className="h-md-100 mb-5 mb-xl-10"
            description="Units Online"
            color="#44A3C2"
            total={data.units}
            img={toAbsoluteUrl("")}
          />
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10 ">
          <CardsWidget21
            className="h-md-100 mb-5 mb-xl-10"
            description="Overdue Invoices"
            color="#e3d346"
            total={data.invoice}
            img={toAbsoluteUrl("")}
          />
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10 ">
          <CardsWidget21
            className="h-md-100 mb-5 mb-xl-10"
            description="Total Outstanding"
            color="#2ea64e"
            total={data.outstanding}
            img={toAbsoluteUrl("")}
          />
        </div>
        {/* end::Col */}
      </div>

      <div
        className="row g-5 g-xl-10 mb-5 mb-xl-10"
        style={{ display: "flex", justifyContent: "center" }}
      >
        {/* begin::Col */}
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
          <CardsWidget21
            className="h-md-100 mb-5 mb-xl-10"
            description="Trouble Ticket"
            color="#338B86"
            total={data.trouble_tickets}
            img={toAbsoluteUrl("")}
          />
        </div>
        {/* end::Col */}
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
          <CardsWidget21
            className="h-md-100 mb-5 mb-xl-10"
            description="Service Ticket"
            color="#C25D44"
            total={data.service_tickets}
            img={toAbsoluteUrl("")}
          />
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
          <CardsWidget21
            className="h-md-100 mb-5 mb-xl-10"
            description="Training Ticket"
            color="#447EC2"
            total={data.training_tickets}
            img={toAbsoluteUrl("")}
          />
        </div>
      </div>

      {/* begin::Row */}
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        {/* begin::Col */}
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-5 mb-md-5 mb-xl-10">
          <ChartsWidget1 className="text-danger" />
        </div>
        {/* end::Col */}

        <div className="col-xxl-7">
          <EngageWidget10 className="h-md-100" />
        </div>
      </div>
      {/* end::Row */}
    </>
  );
};

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper, DashboardPage };
