/* eslint-disable jsx-a11y/anchor-is-valid */

type Props = {
  className: string;
};

const ActivitiesTable: React.FC<Props> = ({ className }) => {
  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}

        <div className="card-header border-1 pb-3 pt-5">
          {/* Lets put table in here!! */}
          <div className="row" style={{ textAlign: "left" }}>
            <div className="col">
              <span className="fs-7 text-dark  fw-bold">Date Range From: </span>
              <input className="form-control" type="date" id="" />
            </div>
            <div className="col">
              <span className="fs-7 text-dark fw-bold">Date Range To: </span>
              <input className="form-control" type="date" id="" />
            </div>

            <div className="col">
              <span className="fs-7 text-dark fw-bold ms-1">User: </span>
              <select className="form-select" aria-label="">
                <option value="Sample-user" selected>
                  Sample user
                </option>
              </select>
            </div>

            <div className="col">
              <span className="fs-7 text-dark fw-bold ms-1">Activity: </span>
              <input type="text" className="form-control" />
            </div>

            <div className="col">
              <button className="btn btn-sm btn-success mt-8">Submit</button>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body py-3">
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bold text-muted bg-light">
                  <th className="ps-4 min-w-325px rounded-start">Timestamp</th>
                  <th className="min-w-125px">User</th>
                  <th className="min-w-125px">Type</th>
                  <th className="min-w-200px">Activity</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {/* <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-50px me-5">
                      <img
                        src={toAbsoluteUrl("/media/stock/600x400/img-26.jpg")}
                        className=""
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="#"
                        className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                      >
                        Sant Extreanet Solution
                      </a>
                      <span className="text-muted fw-semibold text-muted d-block fs-7">
                        HTML, JS, ReactJS
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    $2,790
                  </a>
                  <span className="text-muted fw-semibold text-muted d-block fs-7">
                    Paid
                  </span>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    $520
                  </a>
                  <span className="text-muted fw-semibold text-muted d-block fs-7">
                    Rejected
                  </span>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    Bradly Beal
                  </a>
                  <span className="text-muted fw-semibold text-muted d-block fs-7">
                    Insurance
                  </span>
                </td>
                <td>
                  <span className="badge badge-light-primary fs-7 fw-semibold">
                    Approved
                  </span>
                </td>
                <td className="text-end">
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                  >
                    <KTIcon iconName="switch" className="fs-3" />
                  </a>
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1"
                  >
                    <KTIcon iconName="pencil" className="fs-3" />
                  </a>
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-info btn-sm"
                  >
                    <KTIcon iconName="trash" className="fs-3" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-50px me-5">
                      <img
                        src={toAbsoluteUrl("/media/stock/600x400/img-3.jpg")}
                        className=""
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="#"
                        className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                      >
                        Telegram Development
                      </a>
                      <span className="text-muted fw-semibold text-muted d-block fs-7">
                        C#, ASP.NET, MS SQL
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    $4,790
                  </a>
                  <span className="text-muted fw-semibold text-muted d-block fs-7">
                    Paid
                  </span>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    $240
                  </a>
                  <span className="text-muted fw-semibold text-muted d-block fs-7">
                    Rejected
                  </span>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    Chris Thompson
                  </a>
                  <span className="text-muted fw-semibold text-muted d-block fs-7">
                    NBA Player
                  </span>
                </td>
                <td>
                  <span className="badge badge-light-danger fs-7 fw-semibold">
                    In Progress
                  </span>
                </td>
                <td className="text-end">
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                  >
                    <KTIcon iconName="switch" className="fs-3" />
                  </a>
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                  >
                    <KTIcon iconName="pencil" className="fs-3" />
                  </a>
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                  >
                    <KTIcon iconName="trash" className="fs-3" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-50px me-5">
                      <img
                        src={toAbsoluteUrl("/media/stock/600x400/img-9.jpg")}
                        className=""
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="#"
                        className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                      >
                        Payroll Application
                      </a>
                      <span className="text-muted fw-semibold text-muted d-block fs-7">
                        PHP, Laravel, VueJS
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    $4,390
                  </a>
                  <span className="text-muted fw-semibold text-muted d-block fs-7">
                    Paid
                  </span>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    $593
                  </a>
                  <span className="text-muted fw-semibold text-muted d-block fs-7">
                    Rejected
                  </span>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    Zoey McGee
                  </a>
                  <span className="text-muted fw-semibold text-muted d-block fs-7">
                    Ruby Developer
                  </span>
                </td>
                <td>
                  <span className="badge badge-light-success fs-7 fw-semibold">
                    Success
                  </span>
                </td>
                <td className="text-end">
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                  >
                    <KTIcon iconName="switch" className="fs-3" />
                  </a>
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                  >
                    <KTIcon iconName="pencil" className="fs-3" />
                  </a>
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                  >
                    <KTIcon iconName="trash" className="fs-3" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-50px me-5">
                      <img
                        src={toAbsoluteUrl("/media/stock/600x400/img-18.jpg")}
                        className=""
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="#"
                        className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                      >
                        HR Management System
                      </a>
                      <span className="text-muted fw-semibold text-muted d-block fs-7">
                        Python, PostgreSQL, ReactJS
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    $7,990
                  </a>
                  <span className="text-muted fw-semibold text-muted d-block fs-7">
                    Paid
                  </span>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    $980
                  </a>
                  <span className="text-muted fw-semibold text-muted d-block fs-7">
                    Rejected
                  </span>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    Brandon Ingram
                  </a>
                  <span className="text-muted fw-semibold text-muted d-block fs-7">
                    Insurance
                  </span>
                </td>
                <td>
                  <span className="badge badge-light-info fs-7 fw-semibold">
                    Rejected
                  </span>
                </td>
                <td className="text-end">
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                  >
                    <KTIcon iconName="switch" className="fs-3" />
                  </a>
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                  >
                    <KTIcon iconName="pencil" className="fs-3" />
                  </a>
                  <a
                    href="#"
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                  >
                    <KTIcon iconName="trash" className="fs-3" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-50px me-5">
                      <img
                        src={toAbsoluteUrl("/media/stock/600x400/img-8.jpg")}
                        className=""
                        alt=""
                      />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="#"
                        className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                      >
                        Telegram Mobile
                      </a>
                      <span className="text-muted fw-semibold text-muted d-block fs-7">
                        HTML, JS, ReactJS
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    $5,790
                  </a>
                  <span className="text-muted fw-semibold text-muted d-block fs-7">
                    Paid
                  </span>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    $750
                  </a>
                  <span className="text-muted fw-semibold text-muted d-block fs-7">
                    Rejected
                  </span>
                </td>
                <td>
                  <a
                    href="#"
                    className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                  >
                    Natali Trump
                  </a>
                  <span className="text-muted fw-semibold text-muted d-block fs-7">
                    Insurance
                  </span>
                </td>
                <td>
                  <span className="badge badge-light-warning fs-7 fw-semibold">
                    Approved
                  </span>
                </td>
                {actioncolumn ? (
                  <td className="text-end">
                    <a
                      href="#"
                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                    >
                      <KTIcon iconName="switch" className="fs-3" />
                    </a>
                    <a
                      href="#"
                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                    >
                      <KTIcon iconName="pencil" className="fs-3" />
                    </a>
                    <a
                      href="#"
                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                    >
                      <KTIcon iconName="trash" className="fs-3" />
                    </a>
                  </td>
                ) : null}
              </tr> */}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  );
};

export { ActivitiesTable };
