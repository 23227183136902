import React, { useEffect } from "react";

const ModalBackdropHandler = () => {
  useEffect(() => {
    const handlePathChange = () => {
      const modalBackdrop: any = document.querySelector(".modal-backdrop");

      if (modalBackdrop && modalBackdrop.classList.contains("show")) {
        modalBackdrop.classList.remove("show");
        modalBackdrop.classList.add("hide");
        modalBackdrop.style.display = "none";
      }
    };

    // Initial path check (on component mount)
    handlePathChange();

    // Listen for changes in history (back/forward navigation)
    window.addEventListener("popstate", handlePathChange);

    // Optional: Handle pushState or replaceState
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (...args) {
      originalPushState.apply(this, args);
      handlePathChange();
    };

    window.history.replaceState = function (...args) {
      originalReplaceState.apply(this, args);
      handlePathChange();
    };

    // Cleanup
    return () => {
      window.removeEventListener("popstate", handlePathChange);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);

  return null; // No UI component needed
};

export default ModalBackdropHandler;

export const ModalBackdropHandlerByMutation = () => {
  useEffect(() => {
    const handleBackdropRemoval = () => {
      const modalBackdrop = document.querySelector(".modal-backdrop");
      const modal = document.querySelector(".modal.show"); // Check if any modal is active

      if (!modal && modalBackdrop) {
        modalBackdrop.remove(); // Remove the backdrop if no modals are visible
      }
    };

    // Observe changes in the body (where modals and backdrops are typically added)
    const observer = new MutationObserver(handleBackdropRemoval);
    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  return null; // No UI needed
};
