import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../modules/Auth/core/_requests";

export class ProvidersApi {
  private url: string = "settings/providers";
  private podAuthUrl: string = "integration/pod/login";
  private unitgroupUrl: string = "integration/cms/unitgroups";
  private cmsPlanUrl: string = "integration/cms/billing-plans";
  private authKoreApi: string = "integration/kore/login";
  createProviders = (data: any) => {
    return httpPost(this.url, data);
  };

  //update Providers
  updateProviders = (id: number | string, data: any) => {
    return httpPut(`${this.url}/${id}`, data);
  };

  authenticatePOD = (data: any) => {
    return httpPost(`${this.podAuthUrl}`, data);
  };
  getKoreTokens = (data: any) => {
    return httpPost(`${this.authKoreApi}`, data);
  };
  //Get all Providers
  pullAllProviders = (params?: string) => {
    return httpGet(`${this.url}${params ? "?" + params : ""}`);
  };

  //Get one Providers
  getOneProviders = (id: number | string) => {
    return httpGet(`${this.url}/${id}`);
  };

  getCmsUnitGroup = (provider_id: number | string) => {
    return httpGet(`${this.unitgroupUrl}?provider_id=${provider_id}`);
  };

  getCmsPlan = (provider_id: number | string) => {
    return httpGet(`${this.cmsPlanUrl}?provider_id=${provider_id}`);
  };

  //Get all Providers
  deleteProviders = (id: number | string) => {
    return httpDelete(`${this.url}/${id}`);
  };

  extractProviders = (results: any) => {
    if (results.data && results.data.status) {
      return results.data.data;
    } else {
      return false;
    }
  };

  errorMessage = (results: any) => {
    if (results.data && !results.data.status) {
      return results.data.message;
    } else {
      return false;
    }
  };
}
