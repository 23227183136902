import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { SidebarMenuItem } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem";
import { SidebarMenuItemWithSub } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItemWithSub";
import { Suspense, lazy } from "react";
import { SimpleLoader } from "../../general-components/loaders/simple-loader";
// import { BillAdjustment } from "./components/BillAdjustment";
const subscriptionsBreadCrumbs: Array<PageLink> = [
  {
    title: "Subscriptions",
    path: "/billing/subscriptions",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const SubscriptionPage = () => {
  const Dashboard = lazy(() => import("./components/dashboard"));
  const Create = lazy(() => import("./components/create"));
  const List = lazy(() => import("./components/subscriptions"));
  const Bills = lazy(() => import("./components/Bills"));
  const BillAdjustment = lazy(
    () => import("./components/bill-adjustment/BillAdjustment")
  );
  const Report = lazy(() => import("./components/Report"));
  const BillingStatement = lazy(
    () => import("./components/statement/Statement")
  );
  const ViewSubscription = lazy(
    () => import("./components/subscriptions/view-subscription")
  );
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="dashboard"
          element={
            <>
              <PageTitle breadcrumbs={subscriptionsBreadCrumbs}>
                Dashboard
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <Dashboard />
              </Suspense>
            </>
          }
        />
        <Route
          path="new"
          element={
            <>
              <PageTitle breadcrumbs={subscriptionsBreadCrumbs}>
                Create New
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <Create />
              </Suspense>
            </>
          }
        />
        <Route
          path="subscriptions"
          element={
            <>
              <PageTitle breadcrumbs={subscriptionsBreadCrumbs}>List</PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <List />
              </Suspense>
            </>
          }
        />
        <Route
          path="subscriptions/:id"
          element={
            <>
              <PageTitle breadcrumbs={subscriptionsBreadCrumbs}>View</PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ViewSubscription />
              </Suspense>
            </>
          }
        />
        <Route
          path="bills"
          element={
            <>
              <PageTitle breadcrumbs={subscriptionsBreadCrumbs}>
                Bills
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <Bills />
              </Suspense>
            </>
          }
        />
        <Route
          path="adjustments"
          element={
            <>
              <PageTitle breadcrumbs={subscriptionsBreadCrumbs}>
                Bill Adjustments
              </PageTitle>{" "}
              <Suspense fallback={<SimpleLoader />}>
                <BillAdjustment />
              </Suspense>
            </>
          }
        />
        <Route
          path="report"
          element={
            <>
              <PageTitle breadcrumbs={subscriptionsBreadCrumbs}>
                Report
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <Report />
              </Suspense>
            </>
          }
        />
        <Route
          path="statement"
          element={
            <>
              <PageTitle breadcrumbs={subscriptionsBreadCrumbs}>
                Billing Statement
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <BillingStatement />
              </Suspense>
            </>
          }
        />

        <Route index element={<Navigate to="/billing/subscriptions" />} />
      </Route>
    </Routes>
  );
};

export default SubscriptionPage;

//=============== MODULES ROUTES =============
export const SubscriptionsRoutes = () => (
  <SidebarMenuItemWithSub
    to="/billing"
    title="Billing"
    fontIcon="bi-person"
    icon="dollar"
  >
    {/* <SidebarMenuItem
      to="/subscriptions/dashboard"
      title="Dashboard"
      icon="chart-simple-2"
      fontIcon="bi-person"
    /> */}
    {/* <SidebarMenuItem
      to="/subscriptions/new"
      title="Create New"
      icon="burger-menu-4"
      fontIcon="bi-person"
    /> */}
    <SidebarMenuItem
      to="/billing/subscriptions"
      title="Subscriptions"
      icon="abstract-37"
      fontIcon="bi-person"
    />
    <SidebarMenuItem
      to="/billing/bills"
      title="Bills"
      icon="some-files"
      fontIcon="bi-person"
    />
    <SidebarMenuItem
      to="/billing/adjustments"
      title="Adjustments"
      icon="update-file"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/billing/statement"
      title="Statement"
      icon="document"
      fontIcon="bi-person"
    />
    {/* <SidebarMenuItem
      to="/subscriptions/report"
      title="Report"
      icon="scroll"
      fontIcon="bi-person"
    /> */}
  </SidebarMenuItemWithSub>
);
