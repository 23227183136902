/* eslint-disable react/jsx-no-target-blank */
import React from "react";
// import { PurchasesRoutes } from "../../../../../app/modules/Purchases/PurchasesPage";
// import { IntegrationRoutes } from "../../../../../app/modules/Integration/IntegrationPage";
import { ReportsRoutes } from "../../../../../app/modules/Reports/ReportsPage";
import { SettingsRoutes } from "../../../../../app/modules/Settings/SettingsPage";
import { InventoryRoutes } from "../../../../../app/modules/Inventory/InventoryPage";
// import { UnitsAndProvidersRoutes } from "../../../../../app/modules/UnitsProviders/UnitsAndProvidersPage";
// import { CashbookRoutes } from "../../../../../app/modules/CashBook/CashbookPage";
// import { ExpensesRoutes } from "../../../../../app/modules/Expenses/ExpensesPage";
import { ProjectTrackerRoutes } from "../../../../../app/modules/ProjectTracker/ProjectTrackerPage";
import { MinutesTrackerRoutes } from "../../../../../app/modules/MinutesTracker/MeetingManagerPage";
import { ActionTrackerRoutes } from "../../../../../app/modules/ActionTracker/ActionTrackerPage";
import { InvoicingRoutes } from "../../../../../app/modules/Finance/FinancePage";
import { OrderFulFillmentRoutes } from "../../../../../app/modules/OrderFulfillment/OrderFulFillmentPage";
import { SalesManagementRoutes } from "../../../../../app/modules/SalesManagement/SalesManagementPage";
import { SubscriptionsRoutes } from "../../../../../app/modules/Subscriptions/subscriptionPage";
import { ContactsRoutes } from "../../../../../app/modules/Contacts/ContactPage";
import { DashboardRoutes } from "../../../../../app/modules/Dashboard/dashboardPage";
import { WorkflowRoutes } from "../../../../../app/modules/workflow/workflowPage";

const SidebarMenuMain = () => {
  return (
    <>
      <DashboardRoutes />
      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' /> */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            - Modules -
          </span>
        </div>
      </div>
      <ContactsRoutes />
      <SubscriptionsRoutes />
      <WorkflowRoutes />
      <InvoicingRoutes />
      <InventoryRoutes />
      <SalesManagementRoutes />
      {/* <CustomerServiceRoutes /> */}
      {/* <OrderFulFillmentRoutes /> */}
      <ActionTrackerRoutes />
      <MinutesTrackerRoutes />
      <ProjectTrackerRoutes />
      {/* <ExpensesRoutes /> */}
      {/* <CashbookRoutes /> */}
      {/* <UnitsAndProvidersRoutes /> */}
      {/* <PurchasesRoutes /> */}
      {/* <IntegrationRoutes /> */}

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            - Reports -
          </span>
        </div>
      </div>
      <ReportsRoutes />

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            - Administration -
          </span>
        </div>
      </div>

      <SettingsRoutes />
      {/* <SidebarMenuItem
        to="/crafted/pages/subscriptions/list"
        title="Subscriptions"
        fontIcon="bi-archive"
        icon="dollar"
      />
      <SidebarMenuItem
        to="/crafted/pages/subscriptions/bills"
        title="Bills"
        fontIcon="bi-archive"
        icon="finance-calculator"
      />
      <SidebarMenuItem
        to="/crafted/pages/orders/order"
        title="Orders"
        icon="office-bag"
        fontIcon="bi-person"
      />
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Human Resources
          </span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to="/crafted/pages/customer"
        title="Customer Service"
        fontIcon="bi-sticky"
        icon="question"
      >
        <SidebarMenuItem
          to="/crafted/pages/customer/service"
          title="Service Ticket"
          icon="user-edit"
          fontIcon="bi-person"
        />
        <SidebarMenuItem
          to="/crafted/pages/customer/training"
          title="Training Ticket"
          icon="profile-user"
          fontIcon="bi-person"
        />
        <SidebarMenuItem
          to="/crafted/pages/customer/trouble"
          title="Trouble Ticket"
          icon="profile-circle"
          fontIcon="bi-person"
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to="/crafted/pages/people/contacts"
        title="People"
        icon="people"
        fontIcon="bi-person"
      />
      <SidebarMenuItem
        to="/crafted/pages/units/groups"
        title="Unit Groups"
        fontIcon="bi-sticky"
        icon="car-2"
      /> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/pages/units'
        title='Units & Providers'
        fontIcon='bi-sticky'
        icon='car-2'
      >
        <SidebarMenuItem
          to='/crafted/pages/units/groups'
          title='Unit Groups'
          icon='car-3'
          fontIcon='bi-person'
        />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/pages/subscriptions'
        title='Subscriptions'
        fontIcon='bi-archive'
        icon='dollar'
      >
        <SidebarMenuItem
          to='/crafted/pages/subscriptions/list'
          title='List'
          fontIcon='bi-archive'
          icon='element-5'
        />
        <SidebarMenuItem
          to='/crafted/pages/subscriptions/bills'
          title='Bills'
          fontIcon='bi-archive'
          icon='finance-calculator'
        />
      </SidebarMenuItemWithSub> */}
    </>
  );
};

export { SidebarMenuMain };
