import { useEffect, useState } from "react";
import { ProvidersApi } from "../../../apis/providersApi";
import { ProvidersType } from "../../../modules/Settings/components/providersType";

const API = new ProvidersApi();
export function useGetProviders() {
  const [isLoadingProviders, setIsLoadingProviders] = useState(false);
  const [providersErrors, setProvidersError] = useState<string>("");
  const [providersList, setProvidersList] = useState<ProvidersType[]>([]);
  const tryFetchProviders = async () => {
    try {
      setIsLoadingProviders(true);
      setProvidersError("");

      let results = await API.pullAllProviders();
      results = API.extractProviders(results);
      if (results) {
        setProvidersList(results as any);
      } else {
        setProvidersList([]);
        setProvidersError("An Error has occured");
      }
    } catch (ex: any) {
      setProvidersError("An Error has occured");
    } finally {
      setIsLoadingProviders(false);
    }
  };

  const mapForListItems = (ii: ProvidersType) => {
    return {
      value: ii.provider_id as string,
      label: ii.provider_name as string,
    };
  };
  useEffect(() => {
    tryFetchProviders();
  }, []);

  return {
    isLoadingProviders,
    providersErrors,
    providersList,
    mapForListItems,
  };
}
