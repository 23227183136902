import styled from "styled-components";
import {
  MIS_BLACK,
  MIS_BLUE,
  MIS_DANGER,
  MIS_DANGER_LIGHT,
  MIS_MUTED,
  MIS_SUCCESS,
  MIS_SUCCESS_LIGHT,
  MIS_WARNING,
  MIS_WARNING_LIGHT,
} from "../../../_metronic/mis.themes";

export const DangerBadge = styled.span`
  color: ${MIS_DANGER};
  background-color: ${MIS_DANGER_LIGHT};
  display: flex-inline;
  align-items: center;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 4px;
`;

export const SuccessBadge = styled.span`
  color: ${MIS_SUCCESS};
  background-color: ${MIS_SUCCESS_LIGHT};
  display: flex-inline;
  align-items: center;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 4px;
`;

export const WarningBadge = styled.span`
  color: ${MIS_WARNING};
  background-color: ${MIS_WARNING_LIGHT};
  display: flex-inline;
  align-items: center;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 4px;
`;

export const MutedBadge = styled.span`
  color: ${MIS_BLACK};
  background-color: ${MIS_MUTED};
  display: flex-inline;
  align-items: center;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 4px;
`;

export const InfoBadge = styled.span`
  color: #fff;
  background-color: ${MIS_BLUE};
  display: flex-inline;
  align-items: center;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 4px;
`;
