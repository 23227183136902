/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";

import { useFormik } from "formik";
import { getUserByToken, login } from "../core/_requests";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAuth } from "../core/Auth";
import { AuthModel } from "../core/_models";
import axios from "axios";
import { BASE_URL, LAST_VISITED } from "../../Profile/components/constants";
import { APP_NAME } from "../../../../_metronic/mis.themes";
import { getError } from "../../../utils/general-utils";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email address is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

console.log(process.env.REACT_APP_ENV);
const initialValues = {
  email: "",
  password: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  document.title = `Login | ${APP_NAME}`;
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const [twoFAflows, setTwoFAflows] = useState<
    AuthModel | { ["2fa"]: false } | any
  >({
    "2fa": false,
  });
  const [code, setCode] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const navigate = useNavigate();
  const location: any = useLocation();
  const alignFrontEndAuth = async (results: any) => {
    try {
      const { data: auth } = results;

      saveAuth(auth);
      const { data: user } = await getUserByToken(auth.api_token);
      setCurrentUser(user);
      let lastPath = localStorage.getItem(LAST_VISITED);
      console.log(`${lastPath},`);
      //TODO tobe implemented on 2fa page
      if (lastPath) {
        navigate(lastPath as string); //i think this would work
      }
    } catch (ex: any) {}
  };
  const verifyOtp = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      let results = await axios.post(`${BASE_URL}auth/verify2fa`, {
        email,
        otp_token: code,
        secret_key: twoFAflows.secret_key,
      });
      await alignFrontEndAuth(results);
    } catch (ex: any) {
    } finally {
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTwoFAflows({ "2fa": false });
      try {
        // const { data: auth } = await login(values.email, values.password);
        const results = await login(values.email, values.password);
        setEmail(values.email);

        if (!results.data.status) {
          console.log("Its an error");
        }
        if (!results.data["2fa"]) {
          //Non 2FA tings goes here
          //We're going to look at data model later

          await alignFrontEndAuth(results);
          return;
        }
        //typically 2FA flow
        setTwoFAflows(results.data);
      } catch (error) {
        console.error(error); //TODO streamline backend errors into the form
        saveAuth(undefined);
        setStatus("The login details are incorrect");
        setSubmitting(false);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={twoFAflows.status ? verifyOtp : formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-1">
        <div className=" m-2">
          {/* begin::Logo */}
          <a target="_blank" href="https://www.trackmis.com/" className="mb-12">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/mis_logo.png")}
              className="h-90px"
            />
          </a>
          {/* end::Logo */}
        </div>
      </div>
      {/* begin::Heading */}
      <>
        {!twoFAflows.secret_key && twoFAflows.status ? (
          <p className="mt-5">
            <span className="fs-2">Welcome back, {twoFAflows.first_name}.</span>{" "}
            <br />
            Please enter your password and OTP to login.
          </p>
        ) : null}
      </>
      {/* begin::Separator */}
      <div className="separator my-5"></div>
      {/* end::Separator */}

      {formik.status ? (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      ) : (
        <div></div>
      )}

      <>
        {twoFAflows["2fa"] ? (
          <>
            {twoFAflows.secret_key ? (
              <QrDisplay
                email={email}
                secret={twoFAflows.secret_key}
                name={twoFAflows.first_name}
              />
            ) : null}
            <div className="fv-row mb-8">
              <label className="form-label fs-6 fw-bolder text-dark required">
                OTP
              </label>
              <input
                placeholder="938309"
                // {...formik.getFieldProps("email")}
                // className={clsx(
                //   "form-control bg-solid",
                //   { "is-invalid": formik.touched.email && formik.errors.email },
                //   {
                //     "is-valid": formik.touched.email && !formik.errors.email,
                //   }
                // )}
                className="form-control bg-solid"
                type="text"
                name="codes"
                value={code}
                autoComplete="off"
                onChange={(e: any) => {
                  setCode(e.target.value);
                }}
              />
            </div>
          </>
        ) : (
          <>
            {/* begin::Form group */}

            <div className="fv-row mb-8">
              <label className="form-label fs-6 fw-bolder text-dark required">
                Email Address
              </label>
              <input
                placeholder="Email Address"
                {...formik.getFieldProps("email")}
                className={clsx(
                  "form-control bg-solid",
                  { "is-invalid": formik.touched.email && formik.errors.email },
                  {
                    "is-valid": formik.touched.email && !formik.errors.email,
                  }
                )}
                type="email"
                name="email"
                autoComplete="off"
              />
              {formik.touched.email && formik.errors.email && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className="fv-row mb-3">
              <label className="form-label fw-bolder text-dark fs-6 mb-0 required">
                Password
              </label>
              <input
                type="password"
                placeholder="**********"
                autoComplete="off"
                {...formik.getFieldProps("password")}
                className={clsx(
                  "form-control bg-solid",
                  {
                    "is-invalid":
                      formik.touched.password && formik.errors.password,
                  },
                  {
                    "is-valid":
                      formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
          </>
        )}
      </>

      {/* begin::Wrapper */}
      <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
        <div />

        {/* begin::Link */}
        <Link to="/auth/forgot-password" className="link-danger">
          Forgot Password?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className="d-grid mb-10">
        <button
          type={"submit"}
          id="kt_sign_in_submit"
          className="btn  btn-danger"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className="indicator-label">
              {twoFAflows.status ? "Verify OTP" : "Login"}
            </span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className="text-gray-600 text-center fw-semibold fs-6">
        <a
          target="_blank"
          href="https://www.trackmis.com/"
          className="text-gray-600"
        >
          {" "}
          © Data Convergence Inc.
        </a>
      </div>
    </form>
  );
}

interface QrDisplayProps {
  email?: string;
  secret?: string;
  name?: string;
}
export const QrDisplay: FC<QrDisplayProps> = ({ email, secret, name }) => {
  let url = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=otpauth://totp/${email}?secret=${secret}&issuer=TrackMIS`;

  return (
    <div className="d-flex align-items-center">
      <p>
        <span className="fs-2">Welcome {name},</span> <br />
        Looks like you're here for the first time. Please download Google
        Authenticator (
        <a
          href="https://apps.apple.com/us/app/google-authenticator/id388497605"
          target="_blank"
          rel="noreferrer"
        >
          iOS
        </a>{" "}
        |{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US"
          target="_blank"
          rel="noreferrer"
        >
          Android
        </a>
        ) or Microsoft Authenticator (
        <a
          href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
          target="_blank"
          rel="noreferrer"
        >
          iOS
        </a>{" "}
        |{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_US&pli=1"
          target="_blank"
          rel="noreferrer"
        >
          Android
        </a>
        ) on your mobile phone. Then scan the QR code to get the OTP configured.
      </p>
      <div className="p-4 bg-light mx-auto">
        <img src={url} alt="qrcode" className="mx-auto" />
      </div>
    </div>
  );
};
