import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../modules/Auth/core/_requests";

export class CurrencyApi {
  constructor() {}
  private url: string = "settings/currencies";
  createCurrency = async (data: any) => {
    let results = await httpPost(this.url, data);
    return this.extractCurrency(results);
  };

  //update Currency
  updateCurrency = async (id: number | string, data: any) => {
    let results = await httpPut(`${this.url}/${id}`, data);
    return this.extractCurrency(results);
  };

  //Get all Currency
  pullAllCurrency = async () => {
    let results = await httpGet(this.url);
    return this.extractCurrency(results);
  };

  //Get one Currency
  getOneCurrency = async (id: number | string) => {
    let results = await httpGet(`${this.url}/${id}`);
    return this.extractCurrency(results);
  };

  //Get all Currency
  deleteCurrency = async (id: number | string) => {
    let results = await httpDelete(`${this.url}/${id}`);
    return this.extractCurrency(results);
  };

  extractCurrency = (results: any) => {
    if (results.data && results.data.status) {
      return results.data.data;
    } else {
      return false;
    }
  };
}
