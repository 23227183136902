import clsx from "clsx";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  Search,
  ThemeModeSwitcher,
} from "../../../partials";
import { useLayout } from "../../core";
import { getAuth, useAuth } from "../../../../app/modules/Auth";
import { UserPhoto } from "../../../../app/general-components/elements/user-photo";
import { BASE_URL } from "../../../../app/modules/Profile/components/constants";
const itemClass = "ms-1 ms-lg-3";
const btnClass =
  "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px";
const userAvatarClass = "symbol-35px symbol-md-40px";
const btnIconClass = "fs-1";

const Navbar = () => {
  const { config } = useLayout();
  const { currentUser } = useAuth();
  const auth = getAuth();

  return (
    <div className="app-navbar flex-shrink-0 no-print">
      {/* <div className={clsx("app-navbar-item align-items-stretch", itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-danger btn-custom')} />
      </div> */}

      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          style={{ display: "flex", alignItems: "center" }}
        >
          {/* <img src={toAbsoluteUrl("/media/avatars/blank.png")} alt="" /> */}
          <UserPhoto
            first_word={currentUser?.first_name}
            second_word={currentUser?.last_name}
            size={3}
            path={
              auth?.avatar_file_url
                ? `${BASE_URL.slice(0, -1)}${auth.avatar_file_url}`
                : ""
            }
          />
          <div
            className="fw-bolder d-flex align-items-center fs-5"
            style={{ color: "white", marginLeft: "10px" }}
          >
            {currentUser?.first_name} {currentUser?.last_name}
            <i className="bi bi-caret-down-fill m-1 text-light"></i>
          </div>
        </div>
        <HeaderUserMenu />
      </div>

      {/* {config.app?.header?.default?.menu?.display && (
        <div
          className="app-navbar-item d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="kt_app_header_menu_toggle"
          >
            <KTIcon iconName="text-align-left" className={btnIconClass} />
          </div>
        </div>
      )} */}
    </div>
  );
};

export { Navbar };
