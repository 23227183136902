import { useEffect, useState } from "react";
import { WorkFlowTypeType } from "./types.type";
import { WorkflowTypesApi } from "../../../../../apis/workflowTypeApi";

const API = new WorkflowTypesApi();
export const useGetWorkflowTypes = (): {
  isFetchingTypes: boolean;
  workflowTypes: WorkFlowTypeType[];
} => {
  const [isFetchingTypes, setIsFetchingTypes] = useState<boolean>(false);
  const [workflowTypes, setWorkflowTypes] = useState<WorkFlowTypeType[]>([]);

  const tryFetchType = async () => {
    try {
      setIsFetchingTypes(true);
      let results: any = await API.pullAllWorkFlowType();
      results = API.extractTypes(results);
      if (results) {
        setWorkflowTypes(results);
      }
    } catch (ex: any) {
    } finally {
      setIsFetchingTypes(false);
    }
  };

  useEffect(() => {
    tryFetchType();
  }, []);

  return { isFetchingTypes, workflowTypes };
};

export const useGetWorkflowType = (
  wft_id: string | number
): { wft: WorkFlowTypeType | null; isFetchingTypes: boolean } => {
  const [isFetchingTypes, setIsFetchingTypes] = useState<boolean>(false);
  const [workflowType, setWorkflowType] = useState<WorkFlowTypeType | null>(
    null
  );

  const tryFetchType = async () => {
    try {
      setIsFetchingTypes(true);
      let results: any = await API.getOneWorkFlowType(wft_id);
      results = API.extractTypes(results);
      if (results) {
        setWorkflowType(results);
      }
    } catch (ex: any) {
    } finally {
      setIsFetchingTypes(false);
    }
  };

  useEffect(() => {
    tryFetchType();
  }, [wft_id]);

  return {
    wft: workflowType,
    isFetchingTypes,
  };
};
