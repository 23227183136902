import React from "react";
// import {KTIcon} from '../../../helpers'
import { KTIcon } from "../../../_metronic/helpers";
import { Dropdown1 } from "../../../_metronic/partials";
import { FaintedTitle } from "../elements/fainted-title";
import { NavLink } from "react-router-dom";

type ListItemType = {
  title: string;
  hint: string;
  request_id: string | number;
  status: string;
  badgeColor: "success" | "primary" | "warning" | "danger";
};
type Props = {
  className?: string;
  title?: string;
  list: any[];
  intepreter: (item: any) => ListItemType;
};

const ColoredList: React.FC<Props> = ({
  className,
  title,
  list = [],
  intepreter,
}) => {
  const listItems = intepreter ? list?.map(intepreter) : [];

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      {/* <div className="card-header border-0"> */}
        {/* <h3 className="card-title fw-bold text-dark">{title}</h3> */}
        {/* <FaintedTitle title={title as string} /> */}
        {/* 
        <div className="card-toolbar">
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>
          <Dropdown1 />
        </div>
        */}
      {/* </div> */}
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body pt-2 mt-5 overflow-auto" style={{height: "500px"}}>
        {/* begin::Item */}
        {listItems?.map((item: ListItemType, idx: number) => (
          <div className="d-flex align-items-center mb-6" key={idx}>
            {/* begin::Bullet */}
            <span
              className={`bullet bullet-vertical h-40px me-6 bg-${item.badgeColor}`}
            ></span>
            {/* end::Bullet */}
            {/* begin::Checkbox */}
            {/* <div className="form-check form-check-custom form-check-solid mx-5">
              <i
                className={`fs-3x text-${item.badgeColor} bi ${item.status == 'Open' ? `bi-plus-circle-fill` : (item.status == 'Closed' ? `bi-check-circle-fill` : (item.status == 'On Hold' ? `bi-pause-circle-fill` : (item.status == 'Cancelled' ? `bi-x-circle-fill` : `bi-question-circle-fill`)))}`}
                // data-bs-toggle="tooltip"
                // data-bs-placement="top"
                // title="Tooltip on top"
                // data-bs-custom-class="tooltip-dark"
              ></i>
            </div> */}
            {/* end::Checkbox */}
            {/* begin::Description */}
            <div className="flex-grow-1">
              <NavLink
                replace
                to={`/workflow/request/${item.request_id}`}
                className={`className="text-gray-800 text-hover-primary fw-bold fs-6`}
              >
                {item.title}
              </NavLink>
              <span className="text-muted fw-semibold d-block">
                {item.hint}
              </span>
            </div>
            {/* end::Description */}
            <span
              className={`badge badge-light-${item.badgeColor} fs-8 fw-bold`}
            >
              {item.status}
            </span>
          </div>
        ))}
        {/* end:Item END OF GENERATED LIST */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { ColoredList };
