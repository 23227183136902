import { Navigate, Route, Routes, Outlet } from "react-router-dom";

import { PageLink, PageTitle } from "../../../_metronic/layout/core";

import { Topics } from "./components/topics";

import { MeetingsManager } from "./components/meetings-manager";
import { SidebarMenuItem } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem";
import { SidebarMenuItemWithSub } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItemWithSub";

const innerRouteBreadCrumbs: Array<PageLink> = [
  {
    title: "MeetingManager",

    path: "/minutes-tracker/topics",

    isSeparator: false,

    isActive: false,
  },

  {
    title: "",

    path: "",

    isSeparator: true,

    isActive: false,
  },
];

const MeetingManagerPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="topics"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>Topics</PageTitle>
              <Topics />
            </>
          }
        />

        <Route
          path="meetings-manager"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Meetings Manager
              </PageTitle>
              <MeetingsManager />
            </>
          }
        />

        <Route index element={<Navigate to="/minutes-tracker/topics" />} />
      </Route>
    </Routes>
  );
};

export default MeetingManagerPage;

//================ MODULES ROUTES ====================
export const MinutesTrackerRoutes = () => (
  // <SidebarMenuItemWithSub
  //   to="/minutes-tracker"
  //   title="MeetingManager"
  //   fontIcon="bi-sticky"
  //   icon="laptop"
  // >
  //   <SidebarMenuItem
  //     to="/minutes-tracker/topics"
  //     title="Topics"
  //     icon="credit-cart"
  //     fontIcon="bi-person"
  //   />

  //   <SidebarMenuItem
  //     to="/minutes-tracker/meetings-manager"
  //     title="Meetings Manager"
  //     icon="credit-cart"
  //     fontIcon="bi-person"
  //   />
  // </SidebarMenuItemWithSub>
  <></>
);
