/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { ShadowedCard } from "../../../../general-components/panels/shadowed-card";
import { HorizontalApart } from "../../../../general-components/simple-layouts/horizontal-apart";
import { CrumbHeader } from "../../../../general-components/headings/crumb-header";
import { PanelOpenButton } from "../../../../general-components/panels/collapsible-panel";
import { AlignLeft } from "../../../../general-components/simple-layouts/align-left";
import { useParams, useSearchParams } from "react-router-dom";
import { padNumberWithZeros } from "../../../../utils/number-utils";
import { CosApi } from "../../../../apis/cosApi";
import { CosType } from "./cos.type";
import { KTIcon } from "../../../../../_metronic/helpers";
import { mis_date_from_string_using_timezone } from "../../../../utils/date-utils";
import { TWODecimalPlaces } from "../../../Profile/components/util";
import { MISTable } from "../../../../general-components/tables/mis-table";
import { DangerBadge } from "../../../../general-components/elements/badges";
import { CosFilterView } from "./cos.filter.view";
import { SearchUtils } from "../../../../general-components/searches/simple-search";
import { COSVIEWURLMapper as URLMapper } from "./cos.type";
import {
  normalizePagination,
  Paginator,
} from "../../../../general-components/paginator";
const API = new CosApi();
const initialItems: ItemListType = {
  cos_items: [],
  totals: {} as CosItemTotalsType,
};

export default function ViewCos() {
  const [panelShow, setPanelShow] = useState<Boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingItems, setIsLoadingItems] = useState<boolean>(false);
  const [cos, setCos] = useState<CosType | undefined>(undefined);
  const [cosItems, setCosItems] = useState<ItemListType>(initialItems);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pages, setPages] = useState<any>({});
  const { handleQueryChange, updateQueryString, progressPage, replaceParams } =
    SearchUtils(searchParams, setSearchParams, URLMapper);
  const { id } = useParams();

  const tryLoadCos = async () => {
    try {
      setIsLoading(true);
      let results = await API.getOneCostOfService(id as string);
      results = API.extractCos(results);
      if (results) {
        setCos(results as CosType);
      } else {
        setCos(undefined);
      }
    } catch (ex: any) {
      console.log(ex);
      setCos(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  const pullCosItems = async (params?: string) => {
    try {
      setIsLoadingItems(true);
      let results: any = await API.getCostOfServiceItems(id as string, params);
      results = API.extractCos(results);
      if (results) {
        let { totals, cos_items, ...rse } = results;
        console.log(cos_items);
        normalizePagination(
          cos_items,
          (data: any) => {
            setCosItems({ cos_items: data, totals });
          },
          setPages
        );
        // setCosItems(results as any);
      } else {
        setCosItems(initialItems);
      }
    } catch (ex: any) {
      console.log(ex);
      setCosItems(initialItems);
    } finally {
      setIsLoadingItems(false);
    }
  };

  const exportCsv = async (params?: string) => {
    try {
      // setIsLoadingItems(true);
      let results: any = await API.exportCostOfServiceItems(
        id as string,
        params
      );
      console.log(results);
      results = API.extractCos(results);
      console.log(results);
      // if (results) {
      //   let { totals, cos_items, ...rse } = results;
      //   normalizePagination(
      //     cos_items,
      //     (data: any) => {
      //       setCosItems({ cos_items: data, totals });
      //     },
      //     setPages
      //   );
      //   // setCosItems(results as any);
      // } else {
      //   setCosItems(initialItems);
      // }
    } catch (ex: any) {
      console.log(ex);
      // setCosItems(initialItems);
    } finally {
      // setIsLoadingItems(false);
    }
  };

  useEffect(() => {
    tryLoadCos();
    pullCosItems(
      replaceParams(
        searchParams.toString() ? searchParams.toString() : "page=1",
        URLMapper
      )
    );
  }, [searchParams]);
  return (
    <>
      <div>
        <ShadowedCard>
          <HorizontalApart>
            <CrumbHeader
              fromPosition={"Customer Service"}
              url="#"
              title={"CoS #" + padNumberWithZeros(parseInt(id as string), 5)}
            />
          </HorizontalApart>
        </ShadowedCard>

        <div className="row mt-2">
          <div className="col-sm-6">
            <div className="card p-2 ">
              <div className="card-body p-2">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center bg-light-warning rounded p-5 mb-7">
                      <div className="flex-grow-1 me-2">
                        <a
                          href="#"
                          className="fw-bold text-gray-800 text-hover-primary fs-6"
                        >
                          Start Date
                        </a>
                        <span className="text-muted fw-semibold d-block">
                          {cos &&
                            mis_date_from_string_using_timezone(
                              cos.cos_date_start
                            )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center bg-light-success rounded p-5 mb-7">
                      <div className="flex-grow-1 me-2">
                        <a
                          href="#"
                          className="fw-bold text-gray-800 text-hover-primary fs-6"
                        >
                          End Date
                        </a>
                        <span className="text-muted fw-semibold d-block">
                          {cos &&
                            mis_date_from_string_using_timezone(
                              cos.cos_date_end
                            )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-7">
                      <div className="flex-grow-1 me-2">
                        <a
                          href="#"
                          className="fw-bold text-gray-800 text-hover-primary fs-6"
                        >
                          Provider Reference
                        </a>
                        <span className="text-muted fw-semibold d-block">
                          {cos && cos.cos_provider_reference}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center bg-light-info rounded p-5">
                      <div className="flex-grow-1 me-2">
                        <a
                          href="#"
                          className="fw-bold text-gray-800 text-hover-primary fs-6"
                        >
                          Provider
                        </a>
                        <span className="text-muted fw-semibold d-block">
                          {cos && cos.provider_name}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="d-flex align-items-center bg-light-success rounded p-5">
                      <div className="flex-grow-1 me-2">
                        <a
                          href="#"
                          className="fw-bold text-gray-800 text-hover-primary fs-6"
                        >
                          Provider Date
                        </a>
                        <span className="text-muted fw-semibold d-block">
                          {cos &&
                            mis_date_from_string_using_timezone(
                              cos.provider_date
                            )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="d-flex align-items-center bg-light-success rounded p-5 mb-7">
                      <div className="flex-grow-1 me-2">
                        <a
                          href="#"
                          className="fw-bold text-gray-800 text-hover-primary fs-6"
                        >
                          User
                        </a>
                        <span className="text-muted fw-semibold d-block">
                          {cos && cos.user}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className={`card `}>
              <div className="card-body p-0 d-flex flex-column">
                {/* begin::Stats */}
                <div className="card-px pt-5 pb-10 flex-grow-1">
                  {/* begin::Row */}
                  <div className="row g-0 mt-5 mb-10">
                    {/* begin::Col */}
                    <div className="col">
                      <div className="d-flex align-items-center me-2">
                        {/* begin::Symbol */}
                        <div className="symbol symbol-50px me-3">
                          <div className="symbol-label bg-light-info">
                            <KTIcon
                              iconName="dollar"
                              className="fs-1 text-info"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="fs-4 text-dark fw-bold">
                            {cos?.currency_name}
                            {cos?.cos_total_ok}
                          </div>
                          <div className="fs-7 text-muted fw-semibold">
                            TOTAL OK
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end::Col */}

                    {/* begin::Col */}
                    <div className="col">
                      <div className="d-flex align-items-center me-2">
                        {/* begin::Symbol */}
                        <div className="symbol symbol-50px me-3">
                          <div className="symbol-label bg-light-danger">
                            <KTIcon
                              iconName="abstract-26"
                              className="fs-1 text-danger"
                            />
                          </div>
                        </div>
                        {/* end::Symbol */}

                        {/* begin::Title */}
                        <div>
                          <div className="fs-4 text-dark fw-bold">
                            {cos && cos.currency_name}
                            {TWODecimalPlaces(cos?.cos_total_waste as number)}
                          </div>
                          <div className="fs-7 text-muted fw-semibold">
                            TOTAL WASTE
                          </div>
                        </div>
                        {/* end::Title */}
                      </div>
                    </div>
                    {/* end::Col */}
                  </div>
                  {/* end::Row */}

                  {/* begin::Row */}
                  <div className="row g-0">
                    {/* begin::Col */}
                    <div className="col">
                      <div className="d-flex align-items-center me-2">
                        {/* begin::Symbol */}
                        <div className="symbol symbol-50px me-3">
                          <div className="symbol-label bg-light-success">
                            <KTIcon
                              iconName="basket"
                              className="fs-1 text-success"
                            />
                          </div>
                        </div>
                        {/* end::Symbol */}

                        {/* begin::Title */}
                        <div>
                          <div className="fs-4 text-dark fw-bold">
                            {cos && cos.currency_name}{" "}
                            {cos && TWODecimalPlaces(cos.cos_total as number)}
                          </div>
                          <div className="fs-7 text-muted fw-semibold">
                            TOTAL
                          </div>
                        </div>
                        {/* end::Title */}
                      </div>
                    </div>
                    {/* end::Col */}

                    {/* begin::Col */}
                    <div className="col">
                      <div className="d-flex align-items-center me-2">
                        {/* begin::Symbol */}
                        <div className="symbol symbol-50px me-3">
                          <div className="symbol-label bg-light-primary">
                            <KTIcon
                              iconName="cheque"
                              className="fs-1 text-primary"
                            />
                          </div>
                        </div>
                        {/* end::Symbol */}

                        {/* begin::Title */}
                        <div>
                          <div className="fs-4 text-dark fw-bold">
                            {cos && TWODecimalPlaces(cos.cos_waste as number)}%
                          </div>
                          <div className="fs-7 text-muted fw-semibold">
                            WASTE
                          </div>
                        </div>
                        {/* end::Title */}
                      </div>
                    </div>
                    {/* end::Col */}
                  </div>
                  {/* end::Row */}
                </div>
                {/* end::Stats */}

                {/* begin::Chart */}
                <div className="mixed-widget-6-chart card-rounded-bottom"></div>
                {/* end::Chart */}
              </div>
              {/* end::Body */}
            </div>
          </div>
          {/* end of col for WASTE CARD */}
          {/* START OF ITEMS TABLE */}

          <div className="col-sm-12 mt-4 px-4">
            <div className={`card shadown`}>
              <CosFilterView
                searchParams={searchParams}
                handleQueryChange={handleQueryChange}
                updateQueryString={updateQueryString}
                isLoading={isLoadingItems}
              />
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-sm btn-info me-2"
                  onClick={() => {
                    exportCsv(
                      replaceParams(
                        searchParams.toString()
                          ? searchParams.toString()
                          : "page=1",
                        URLMapper
                      )
                    );
                  }}
                >
                  Export
                </button>
              </div>

              <div className="card-body p-0 px-5 d-flex flex-column">
                <MISTable>
                  <thead>
                    <tr>
                      <th colSpan={7}></th>
                      <th colSpan={3} className="text-center fw-bold">
                        Amount {cos?.currency_name}
                      </th>
                      <th></th>
                      <th colSpan={2} className="text-center fw-bold"></th>
                    </tr>
                    <tr>
                      <th className="fw-bold">#</th>
                      <th className="fw-bold min-w-125px">Start Date</th>
                      <th className="fw-bold min-w-125px">End Date</th>
                      <th className="fw-bold min-w-125px">Identification</th>
                      <th className="fw-bold min-w-125px">Subscription</th>
                      <th className="fw-bold min-w-125px">Organization</th>
                      <th className="fw-bold min-w-150px">
                        Subscription Status
                      </th>
                      <th className="fw-bold min-w-125px bg-light text-center">
                        OK
                      </th>
                      <th className="fw-bold min-w-125px  bg-light text-center">
                        SUSPENDED
                      </th>
                      <th className="fw-bold min-w-125px bg-light text-center">
                        UNKNOWN
                      </th>
                      <th className="fw-bold min-w-125px text-center">
                        Flag Status
                      </th>
                      {/* <th className="fw-bold min-w-125px text-center bg-light">
                        Reference
                      </th>
                      <th className="fw-bold min-w-125px text-center bg-light">
                        Status
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {cosItems.cos_items.map(
                      (item: CosItemType, index: number) => (
                        <tr key={item.cosi_id}>
                          <th className="">{index + 1}</th>
                          <th className="">
                            {mis_date_from_string_using_timezone(
                              item.cosi_date_start
                            )}
                          </th>
                          <th className="">
                            {mis_date_from_string_using_timezone(
                              item.cosi_date_end
                            )}
                          </th>
                          <th className="">{item.cosi_identification}</th>
                          <th className="">{item.asset_name}</th>
                          <th className="">{item.organization_name}</th>
                          <th className="">{item.cosi_subscription_status}</th>
                          <th className="text-center">
                            {item.cosi_flag_status === "OK"
                              ? item.cosi_cost_amount
                              : "--"}
                          </th>
                          <th className="text-center">
                            {item.cosi_flag_status === "SUSPENDED"
                              ? item.cosi_cost_amount
                              : "--"}
                          </th>
                          <th className="text-center">
                            {item.cosi_flag_status === "UNKNOWN"
                              ? item.cosi_cost_amount
                              : "--"}
                          </th>
                          <th className="text-center">
                            {item.cosi_flag_status}
                          </th>
                          {/* <th className="text-center">
                            {item.request_id || "--"}
                          </th>
                          <th className="text-center">
                            {item.request_status || "--"}
                          </th> */}
                        </tr>
                      )
                    )}
                    <tr className="bg-light-success">
                      <td colSpan={6}></td>
                      <td className="text-end">
                        <strong>Total OK:</strong>
                        {cosItems.totals.value_ok}
                      </td>

                      <td className="text-end">
                        <strong>Total Suspended:</strong>
                        {cosItems.totals.value_suspended}
                      </td>

                      <td className="text-end">
                        <strong>Total Unknown:</strong>
                        {cosItems.totals.unknown_count}
                      </td>

                      <td className="text-end">
                        <strong>Total Value:</strong>
                        {cosItems.totals.total_value}
                      </td>
                      <td className="text-end">
                        <strong>Count:</strong>
                        {cosItems.totals.count}
                      </td>
                    </tr>
                  </tbody>
                </MISTable>
                <Paginator pages={pages} callback={progressPage} />
              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const ViewCosWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Activity Report</PageTitle>
      <ViewCos />
    </>
  );
};

export { ViewCosWrapper, ViewCos };

type ItemListType = {
  cos_items: CosItemType[];
  totals: CosItemTotalsType;
};
type CosItemType = {
  cosi_id: number | string;
  cosi_date_start?: string;
  cosi_date_end?: string;
  cosi_identification?: string;
  subscription_id?: number | string;
  cosi_subscription_status?: string;
  cosi_cost_amount: number | string;
  cosi_flag_status?: string;
  cosi_flag_notes?: string | null;
  cos_id?: number | string;
  asset_name?: string | null;
  organization_name?: string | null;
  currency_name?: string;
  currency_id?: number | string;
  request_id?: string | number;
  request_status?: string | null;
};

type CosItemTotalsType = {
  count?: number | string;
  value_ok?: number | string;
  value_unknown?: number | string;
  value_suspended?: number | string;
  value_wasted?: number | string;
  total_value?: number | string;
  unknown_count?: number | string;
};
