import { httpGet, httpPost, httpPut } from "../modules/Auth/core/_requests";

export class OverviewDashboardApis {
  private url: string = "reports/main";
  overview = () => {
    return httpGet(`${this.url}/dashboard`);
  };

  //update Department
  updateDepartment = (id: number | string, data: any) => {
    return httpPut(`${this.url}/${id}`, data);
  };

  //Get all Department
  pullAllDepartment = (params?: string) => {
    return httpGet(`${this.url}${params ? `?${params}` : ""}`);
  };
}
