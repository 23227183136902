import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../modules/Auth/core/_requests";

export class ProductsApis {
  private settings_url: string = "settings/product-categories";
  private products_url: string = "inventory/products";
  private location_settings_url: string = "settings/locations";
  //================== ProductCategories END POINTS =============
  //creating ProductCategories
  createProductCategories = async (data: any) => {
    let results = await httpPost(this.settings_url, data);
    return this.extractProductCategoriesSettings(results);
  };
  //extracting results from backend!!

  //update ProductCategories
  async updateProductCategories(id: number | string, data: any) {
    let result = await httpPut(`${this.settings_url}/${id}`, data);
    return this.extractProductCategoriesSettings(result);
  }

  //Get all ProductCategories
  pullAllProductCategories = async (params?: string) => {
    let results = await httpGet(
      `${this.settings_url}${params ? "?" + params : ""}`
    );
    return this.extractProductCategoriesSettings(results);
  };
  extractProductCategoriesSettings(results: any) {
    if (results.data && results.data.status) {
      return results.data.data;
    } else {
      return undefined; //we dont have what we fetched!!
    }
  }

  //Get one ProductCategories
  async getOneProductCategories(id: number | string) {
    if (!id) return;
    let results = await httpGet(`${this.settings_url}/${id}`);
    return this.extractProductCategoriesSettings(results);
  }

  //Get all ProductCategories
  deleteProductCategories(id: number | string) {
    return httpDelete(`${this.settings_url}/${id}`);
  }

  //================== Product&Services END POINTS =============
  //creating Product&Services

  createProductServices = async (data: any) => {
    let results = await httpPost(this.products_url, data);
    return this.extractProductCategoriesSettings(results);
  };

  //update Product&Services
  updateProductServices = async (id: number | string, data: any) => {
    let results = await httpPut(`${this.products_url}/${id}`, data);
    return this.extractProductCategoriesSettings(results);
  };

  //Get all Product&Services
  pullAllProductServices = async (params?: string) => {
    let results = await httpGet(
      `${this.products_url}${params ? "?" + params : ""}`
    );
    return this.extractProductCategoriesSettings(results);
  };

  //Get one Product&Services
  getOneProductServices = async (id: number | string) => {
    let results = await httpGet(`${this.products_url}/${id}`);
    return this.extractProductCategoriesSettings(results);
  };

  //Get all Product&Services
  deleteProductServices = (id: number | string) => {
    return httpDelete(`${this.products_url}/${id}`);
  };

  //================== Locations END POINTS =============
  //creating Locations
  createLocations = async (data: any) => {
    let results = await httpPost(this.location_settings_url, data);
    return this.extractProductCategoriesSettings(results);
  };

  //update Locations
  updateLocations = async (id: number | string, data: any) => {
    let results = await httpPut(`${this.location_settings_url}/${id}`, data);
    return this.extractProductCategoriesSettings(results);
  };

  //Get all Locations
  pullAllLocations = async () => {
    let results = await httpGet(this.location_settings_url);
    return this.extractProductCategoriesSettings(results);
  };

  //Get one Locations
  getOneLocations = async (id: number | string) => {
    let results = await httpGet(`${this.location_settings_url}/${id}`);
    return this.extractProductCategoriesSettings(results);
  };

  //Get all Locations
  deleteLocations = (id: number | string) => {
    return httpDelete(`${this.location_settings_url}/${id}`);
  };
}
