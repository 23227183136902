import { FC } from "react";

type Props = {
  children?: JSX.Element | JSX.Element[];
  title?: string;
  action?: () => void;
  pushLeft?: string;
  nature?: string; //if its a danger, success or info button
  target?: string;
};

export const FlexImmuneButton: FC<Props> = ({
  children,
  title,
  action,
  pushLeft,
  nature,
}) => {
  //we'll update action later
  return (
    <button
      className={`btn btn-sm btn-${
        nature ? nature : "primary"
      } align-self-center ${pushLeft ? pushLeft : ""}`}
      onClick={action}
    >
      {children}
      {title}
    </button>
  );
};

export const FlexImmuneModal: FC<Props> = ({
  children,
  title,
  action,
  pushLeft,
  nature,
  target,
}) => {
  //we'll update action later
  return (
    <button
      className={`btn btn-sm btn-${
        nature ? nature : "primary"
      } align-self-center ${pushLeft ? pushLeft : ""}`}
      data-bs-toggle="modal"
      data-bs-target={target}
    >
      {children}
      {title}
    </button>
  );
};
