import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../modules/Auth/core/_requests";
import { downloadFile } from "./file-download";

export class BillsApi {
  private url: string = "finance/bills";
  createBills = (data: any) => {
    return httpPost(this.url, data);
  };

  //update Bills
  updateBills = (id: number | string, data: any) => {
    return httpPut(`${this.url}/${id}`, data);
  };

  //Get all Bills
  pullAllBills = (params?: string) => {
    return httpGet(`${this.url}${params ? "?" + params : ""}`);
  };

  //Get one Bills
  getOneBills = (id: number | string) => {
    return httpGet(`${this.url}/${id}`);
  };

  //Get all Bills
  deleteBills = (id: number | string, data: any) => {
    return httpPut(`${this.url}/${id}/cancel`, data);
  };

  getBillItems = (id: number | string, params?: string) => {
    return httpGet(`${this.url}/${id}/items${params ? "?" + params : ""}`);
  };

  extractBills = (results: any) => {
    if (results.data && results.data.status) {
      return results.data.data;
    } else {
      return false;
    }
  };

  exportBillFile = (sb_id: number | string) =>
    downloadFile(`${this.url}/${sb_id}/csv`, "bill.csv");

  sendBillEmail = (sb_id: number | string, data: any) => {
    return httpPost(`${this.url}/${sb_id}/email`, data);
  };

  extractError = (results: any) => {
    if (results.data && results.data.status === false) {
      return results.data.data;
    } else {
      return false;
    }
  };
}
