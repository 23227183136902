import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { SidebarMenuItemWithSub } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItemWithSub";
import { SidebarMenuItem } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem";
import { lazy } from "react";

// const Subscriptions = lazy(
//   () => import("./components/subscription/subscription-bars")
// );
// const SubscriptionsPie = lazy(
//   () => import("./components/subscription/pai-chart-example")
// );
const ExpensesDashboard = lazy(
  () => import("./components/expenses/expenses-dashboard")
);
const SalesReport = lazy(
  () => import("./components/Sales-report/sales-report-util/sales-report")
);

const ExpenseReport = lazy(
  () => import("./components/Sales-report/expense-report/expense-report")
);
const SubscriptionRevenueByProducts = lazy(
  () =>
    import(
      "./components/Sales-report/revenue-by-products/subscription-revenue-by-product"
    )
);
const SubscriptionRevenueByCustomer = lazy(
  () =>
    import(
      "./components/Sales-report/revenue-by-customer/subscription-revenue-by-customer"
    )
);

const MonthlySalesClosedReport = lazy(
  () =>
    import(
      "./components/Sales-report/monthly-sales-closed-report/monthly-sales-closed-report"
    )
);
const CollectionReport = lazy(
  () => import("./components/Sales-report/collections/collection-report")
);
const ReceivableReport = lazy(
  () => import("./components/Sales-report/receivables/receivables-report")
);
const LowProductReport = lazy(
  () =>
    import("./components/Sales-report/low-product-report/low-product-report")
);
const WorkflowRequest = lazy(
  () => import("./components/requests/requests-report/requests-report")
);
const innerRouteBreadCrumbs: Array<PageLink> = [
  {
    title: "Reports",
    path: "/reports/sales",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const ReportsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="sales"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Sales Report
              </PageTitle>
              <SalesReport />
            </>
          }
        />
        <Route
          path="collections"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Collection Report
              </PageTitle>
              <CollectionReport />
            </>
          }
        />
        <Route
          path="subsc-revenue-by-products"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Subscription Revenue by product
              </PageTitle>
              <SubscriptionRevenueByProducts />
            </>
          }
        />
        <Route
          path="subsc-revenue-by-customer"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Subscription Revenue by customer
              </PageTitle>
              <SubscriptionRevenueByCustomer />
            </>
          }
        />
        <Route
          path="monthly-sales-closed-report"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Monthly Sales Closed Report
              </PageTitle>
              <MonthlySalesClosedReport />
            </>
          }
        />
        <Route
          path="expenses"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Expense Report
              </PageTitle>
              <ExpenseReport />
            </>
          }
        />
        <Route
          path="receivables"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Receivable Report
              </PageTitle>
              <ReceivableReport />
            </>
          }
        />
        <Route
          path="low-products-report"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Receivable Report
              </PageTitle>
              <LowProductReport />
            </>
          }
        />

        <Route
          path="subscriptions"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Subscription
              </PageTitle>
              {/* <Subscriptions /> */}
            </>
          }
        />
        <Route
          path="pie"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>Pie</PageTitle>
              {/* <SubscriptionsPie /> */}
            </>
          }
        />
        <Route
          path="expenses-dashboard"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Expenses
              </PageTitle>
              <ExpensesDashboard />
            </>
          }
        />
        <Route
          path="workflow-requests"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Requests
              </PageTitle>
              <WorkflowRequest />
            </>
          }
        />

        <Route index element={<Navigate to="/reports/sales" />} />
      </Route>
    </Routes>
  );
};

export default ReportsPage;

//============ THIS IS MODULES'S ROUTES ============
export const ReportsRoutes = () => (
  <SidebarMenuItemWithSub
    to="/reports"
    title="Reports"
    fontIcon="bi-sticky"
    icon="scroll"
  >
    <SidebarMenuItemWithSub
      to="#"
      title="Finance"
      // fontIcon="bi-sticky"
      icon="tag"
    >
      <SidebarMenuItem
        to="/reports/sales"
        title="Sales"
        icon="file"
        fontIcon="bi-person"
      />
      <SidebarMenuItem
        to="/reports/expenses"
        title="Expenses"
        icon="file"
        fontIcon="bi-person"
      />
      <SidebarMenuItem
        to="/reports/subsc-revenue-by-products"
        title="Rev. By Products"
        icon="file"
        fontIcon="bi-person"
      />
      <SidebarMenuItem
        to="/reports/subsc-revenue-by-customer"
        title="Rev. By Customer"
        icon="file"
        fontIcon="bi-person"
      />
      <SidebarMenuItem
        to="/reports/monthly-sales-closed-report"
        title="Monthly closed sales"
        icon="file"
        fontIcon="bi-person"
      />
      <SidebarMenuItem
        to="/reports/collections"
        title="Collections Report"
        icon="file"
        fontIcon="bi-person"
      />
      <SidebarMenuItem
        to="/reports/receivables"
        title="Receivables Report"
        icon="file"
        fontIcon="bi-person"
      />
      <SidebarMenuItem
        to="/reports/low-products-report"
        title="Low Products"
        icon="file"
        fontIcon="bi-person"
      />
    </SidebarMenuItemWithSub>

    <SidebarMenuItemWithSub
      to="#"
      title="Inventory"
      // fontIcon="bi-sticky"
      icon="barcode"
    >
      {/* <SidebarMenuItem
        to="/crafted/pages/units/groups"
        title="Unit Groups"
        icon="car-3"
        fontIcon="bi-person"
      /> */}
    </SidebarMenuItemWithSub>

    <SidebarMenuItemWithSub
      to="#"
      title="Workflows"
      // fontIcon="bi-sticky"
      icon="abstract-4"
    >
      <SidebarMenuItem
        to="/reports/workflow-requests"
        title="Requests"
        icon="car-3"
        fontIcon="bi-person"
      />

      <SidebarMenuItem
        to="/crafted/pages/units/groups"
        title="Unit Groups"
        icon="car-3"
        fontIcon="bi-person"
      />
    </SidebarMenuItemWithSub>

    {/* <SidebarMenuItem
      to="/reports/subscriptions"
      title="Subscriptions"
      icon="credit-cart"
      fontIcon="bi-person"
    />
    <SidebarMenuItem
      to="/reports/pie"
      title="Distribution"
      icon="credit-cart"
      fontIcon="bi-person"
    />
    <SidebarMenuItem
      to="/reports/expenses-dashboard"
      title="Expenses Dashboard"
      icon="credit-cart"
      fontIcon="bi-person"
    />
    <SidebarMenuItem
      to="/reports/report-3"
      title="Report 3"
      icon="credit-cart"
      fontIcon="bi-person"
    />
    <SidebarMenuItem
      to="/reports/report-4"
      title="Report 4"
      icon="credit-cart"
      fontIcon="bi-person"
    />
    <SidebarMenuItem
      to="/reports/report-5"
      title="Report 5"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}
  </SidebarMenuItemWithSub>
);
